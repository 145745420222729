import { isEmpty, SortableTable, Tooltip } from 'components/utils'
import { Badge, Button, Card, Form } from 'front'
import { useContext, useEffect, useMemo, useState } from 'react'
import { ButtonGroup, Col, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { useProject, useUpdateProject } from 'tracker/api'
import { ProjectContext } from 'tracker/Context/ProjectContext'
import AllFurnishings from './AllFurnishings'
import { DateRow } from './DateRow'
import AddEditDateModal from './AddEditDateModal'
import ProjectDates from './ProjectDates'

export default function Dates({ filterOnDeadlineIds, onlyShowTable }) {
    const { fileInfo, projectLoading, updateFileEdit } = useContext(ProjectContext)
    const { data: project } = useProject({ projectId: fileInfo?.project_id })
    const [filterMode, setFilterMode] = useState('relevant') // all, focus, relevant
    const [showAddDate, setShowAddDate] = useState(false)
    const [showAllFurnishings, setShowAllFurnishings] = useState(false)
    const [editFileInfo, setEditFileInfo] = useState({})
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        setEditFileInfo(fileInfo)
    }, [fileInfo])

    let deadlines = editFileInfo?.deadlines ? Object.values(editFileInfo?.deadlines) : []
    deadlines = useMemo(() => {
        let filteredDeadlines = deadlines?.filter((d) => !d?.is_deleted)
        filteredDeadlines = filteredDeadlines?.filter(
            (d) => filterOnDeadlineIds == null || filterOnDeadlineIds.includes(d?._id)
        )

        if (filterMode == 'all') return filteredDeadlines

        filteredDeadlines = filteredDeadlines?.filter(
            (d) =>
                d?.deadline_input !== 'NA' &&
                d?.deadline_status !== 'NA' &&
                d?.deadline_status !== 'IGNORED'
        )

        if (filterMode == 'relevant') return filteredDeadlines

        filteredDeadlines = filteredDeadlines?.filter(
            (d) =>
                !(
                    !!d?.completed_date ||
                    (d?._is_multi_furnishing && d?.furnishing_balance == '0.00')
                )
        )

        if (filterMode == 'focus') return filteredDeadlines
        else return filteredDeadlines
    }, [deadlines, filterMode, filterOnDeadlineIds])

    useEffect(() => {
        if (
            !loaded &&
            deadlines.length === 0 &&
            (filterMode === 'relevant' || filterMode === 'focus')
        ) {
            setFilterMode('all')
            setLoaded(true)
        }
    }, [deadlines, loaded, filterMode])

    const { mutate: updateProject, isLoading: updateProjectLoading } = useUpdateProject({
        projectId: fileInfo?.project_id,
    })

    function updateDeadline(id, update) {
        updateProject({ deadlines: { [id]: { ...update } } })

        setEditFileInfo((current) => {
            const deadlineChange = current?.deadlines?.[id]
            return {
                ...current,
                deadlines: { ...current?.deadlines, [id]: { ...deadlineChange, ...update } },
            }
        })
    }

    function markAllTxDeadlines(id, isNextAction) {
        const deadline = editFileInfo?.deadlines?.[id]
        if (!deadline?._is_tx2 && !deadline?._is_tx3) return

        const updates = {}
        Object.values(editFileInfo?.deadlines || {}).forEach((d) => {
            if ((d?._is_tx2 && deadline?._is_tx2) || (d?._is_tx3 && deadline?._is_tx3)) {
                updates[d._id] = { is_next_action: isNextAction }
            }
        })

        updateProject({ deadlines: updates })

        setEditFileInfo((current) => ({
            ...current,
            deadlines: Object.entries(current?.deadlines || {}).reduce(
                (acc, [key, value]) => ({
                    ...acc,
                    [key]: updates[key] ? { ...value, ...updates[key] } : value,
                }),
                {}
            ),
        }))
    }

    function markAllTxType(type, isNextAction) {
        const updates = {}
        Object.values(editFileInfo?.deadlines || {}).forEach((d) => {
            if (
                !d?.completed_date &&
                !d?.is_deleted &&
                ((type === 'tx2' && d?._is_tx2) ||
                    (type === 'tx3' && d?._is_tx3) ||
                    (type === 'both' && (d?._is_tx2 || d?._is_tx3)))
            ) {
                updates[d._id] = { is_next_action: isNextAction }
            }
        })

        updateProject({ deadlines: updates })

        setEditFileInfo((current) => ({
            ...current,
            deadlines: Object.entries(current?.deadlines || {}).reduce(
                (acc, [key, value]) => ({
                    ...acc,
                    [key]: updates[key] ? { ...value, ...updates[key] } : value,
                }),
                {}
            ),
        }))
    }

    const dateTable = (
        <SortableTable
            all
            skeleton={projectLoading}
            startSort={'_sortkey'}
            startSortBy={'asc'}
            info={deadlines}
            renderRow={(row) => (
                <DateRow
                    key={row?._id}
                    skeleton={projectLoading}
                    row={row}
                    fileInfo={fileInfo}
                    updateDeadline={updateDeadline}
                    markAllTxDeadlines={markAllTxDeadlines}
                    markAllTxType={markAllTxType}
                    project={project}
                    updateFileEdit={updateFileEdit}
                    updateProjectLoading={updateProjectLoading}
                />
            )}
            headers={[
                {
                    name: 'Next',
                    sort: false,
                    className: 'text-center pe-0',
                },
                {
                    name: 'Type',
                    sortField: '_sortkey',
                    className: 'ps-2',
                },
                // Removing at request of NML
                // {
                //     name: 'Status',
                //     sort: false,
                //     className: 'text-center',
                // },
                fileInfo?.project?._is_table_state && {
                    name: 'Balance',
                    sort: false,
                    className: 'text-center',
                },
                {
                    name: 'Due',
                    sortField: 'due_date',
                },
                {
                    name: 'Completed',
                    sortField: 'completed_date',
                },
                {
                    name: '',
                },
            ]}
        />
    )

    if (onlyShowTable) return dateTable

    return (
        <div className='p-3'>
            {isEmpty(project?.deadlines) && !projectLoading && (
                <>
                    <h2>
                        {updateProjectLoading ? (
                            <>
                                <h2>
                                    <i className='fal fa-spinner-third fa-spin me-2' />{' '}
                                    Recalculating
                                </h2>
                            </>
                        ) : (
                            'Please add dates'
                        )}
                    </h2>

                    <div>
                        <Button
                            onClick={() => {
                                updateProject({ _recalculate_dates: true })
                            }}
                            disabled={projectLoading}
                        >
                            Recalculate Dates
                        </Button>
                    </div>
                </>
            )}
            {!projectLoading && (
                <Row className='mb-4'>
                    <Col className='d-flex justify-content-start align-items-center' xs={12}>
                        <ButtonGroup className='me-3 align-self-end mt-2'>
                            <Form.Button
                                variant='white'
                                size='sm'
                                onClick={() => {
                                    setShowAddDate(true)
                                }}
                                icon='fal fa-plus'
                                disable={updateProjectLoading}
                            >
                                Add Date
                            </Form.Button>
                            {fileInfo?.project?._is_table_state && (
                                <Button
                                    size='sm'
                                    onClick={() => {
                                        setShowAllFurnishings(!showAllFurnishings)
                                    }}
                                    icon='fal fa-table'
                                >
                                    Furnishings
                                    <Badge bg='secondary' soft className='ms-2'>
                                        {(fileInfo?.furnishings &&
                                            Object.values(fileInfo?.furnishings)?.filter(
                                                (furn) => !furn?.is_deleted
                                            )?.length) ??
                                            0}
                                    </Badge>
                                </Button>
                            )}
                        </ButtonGroup>
                        <ProjectDates project={project} updateProject={updateProject} />
                    </Col>
                </Row>
            )}
            <Card>
                <Card.Header className='d-flex justify-content-start align-items-center'>
                    {projectLoading ? (
                        <div className='w-100'>
                            <Skeleton className='lh-lg w-100' />
                        </div>
                    ) : (
                        <>
                            <Card.Title>
                                Deadlines
                                {updateProjectLoading && (
                                    <Badge
                                        icon='fal fa-spinner-third fa-spin'
                                        pill
                                        bg='secondary'
                                        soft
                                        className='ms-3'
                                    >
                                        Recalculating
                                    </Badge>
                                )}
                            </Card.Title>
                            <Form.Check
                                className='d-block ms-auto me-5 mb-0 mt-1'
                                type={'switch'}
                                onChange={() => {
                                    updateProject({
                                        project: {
                                            is_conservative_calculation:
                                                !project?.project?.is_conservative_calculation,
                                        },
                                    })
                                }}
                                checked={project?.project?.is_conservative_calculation}
                                label={
                                    <Tooltip
                                        content={`Calculate dates conservatively`}
                                        delay={{
                                            show: 450,
                                            hide: 0,
                                        }}
                                        trigger={['hover']}
                                    >
                                        <span>
                                            Conservative
                                            <i className='fal fa-info-circle fa-sm text-muted ms-2' />
                                        </span>
                                    </Tooltip>
                                }
                                disable={updateProjectLoading}
                                size='xs'
                                style={{ fontSize: 12 }}
                            />
                            {project?.project?.is_completion_fallback_allowed && (
                                <Form.Check
                                    className='d-block me-5 mb-0 mt-1'
                                    type={'switch'}
                                    onChange={() => {
                                        updateProject({
                                            project: {
                                                is_completion_fallback:
                                                    !project?.project?.is_completion_fallback,
                                            },
                                        })
                                    }}
                                    checked={project?.project?.is_completion_fallback}
                                    label={
                                        <Tooltip
                                            content={`Use the last furnishing date if there is no completion date`}
                                            delay={{
                                                show: 450,
                                                hide: 0,
                                            }}
                                            trigger={['hover']}
                                        >
                                            <span>
                                                Fallback to Last Furn
                                                <i className='fal fa-info-circle fa-sm text-muted ms-2' />
                                            </span>
                                        </Tooltip>
                                    }
                                    disable={updateProjectLoading}
                                    size='xs'
                                    style={{ fontSize: 12 }}
                                />
                            )}
                            <ToggleButtonGroup
                                name='datesFilterToggle'
                                value={filterMode}
                                type='radio'
                                className='btn-group-segment'
                                size='xs'
                            >
                                <ToggleButton
                                    value='all'
                                    variant=''
                                    onClick={() => setFilterMode('all')}
                                >
                                    <Tooltip
                                        content={`Show all dates for this project`}
                                        delay={{
                                            show: 450,
                                            hide: 0,
                                        }}
                                        trigger={['hover']}
                                    >
                                        <span className='fw-bold'>All</span>
                                    </Tooltip>
                                </ToggleButton>
                                <ToggleButton
                                    value='relevant'
                                    variant=''
                                    checked={filterMode == 'relevant'}
                                    onClick={() => setFilterMode('relevant')}
                                >
                                    <Tooltip
                                        content={`Hide irrelevant dates (N/A and ignored)`}
                                        delay={{
                                            show: 450,
                                            hide: 0,
                                        }}
                                        trigger={['hover']}
                                    >
                                        <span className='fw-bold'>Relevant</span>
                                    </Tooltip>
                                </ToggleButton>
                                <ToggleButton
                                    value='focus'
                                    variant=''
                                    checked={filterMode == 'focus'}
                                    onClick={() => setFilterMode('focus')}
                                >
                                    <Tooltip
                                        content={`Hide irrelevant dates, incomplete, and those w/ $0 furnishing balance`}
                                        delay={{
                                            show: 450,
                                            hide: 0,
                                        }}
                                        trigger={['hover']}
                                    >
                                        <span className='fw-bold'>Focus</span>
                                    </Tooltip>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </>
                    )}
                </Card.Header>
                {dateTable}
            </Card>
            <AllFurnishings
                show={showAllFurnishings}
                onHide={() => {
                    setShowAllFurnishings(false)
                }}
                loading={updateProjectLoading}
            />
            <AddEditDateModal
                show={showAddDate}
                onHide={() => {
                    setShowAddDate(false)
                }}
                loading={updateProjectLoading}
            />
        </div>
    )
}
