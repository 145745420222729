import classnames from 'classnames'
import { formatDate, Tooltip } from 'components/utils'
import dayjs from 'lib/dayjs'
import { Badge, Button, Form } from 'front'
import { InputGroup, Overlay, Popover } from 'react-bootstrap'
import { BalanceModal } from './BalanceModal'
import DeleteDateModal from './DeleteDeadlineModal'
import LinkTaskModal from './LinkTaskModal'
import { useState, useRef } from 'react'
import { useModalState } from 'hooks'
import AddEditDateModal from './AddEditDateModal'

export const DateRow = ({
    row,
    fileInfo,
    updateDeadline,
    markAllTxDeadlines,
    markAllTxType,
    updateFileEdit,
    project,
    updateProjectLoading,
}) => {
    const notEditable =
        row?.deadline_input === 'NA' ||
        row?.deadline_status === 'NA' ||
        row?.deadline_status === 'IGNORED'

    const ignored = row?.deadline_status == 'IGNORED'
    const type = fileInfo?.deadline_types?.[row?.deadline_type_id]
    //
    return (
        <tr className='align-middle'>
            <NextActionColumn
                row={row}
                fileInfo={fileInfo}
                updateDeadline={updateDeadline}
                markAllTxDeadlines={markAllTxDeadlines}
                markAllTxType={markAllTxType}
                notEditable={notEditable}
            />
            <DateTypeColumn
                notEditable={notEditable}
                type={type}
                row={row}
                fileInfo={fileInfo}
                updateDeadline={updateDeadline}
            />
            {/* Removing at request of NML because it was confusing. Probably temporary. */}
            {/* <StatusColumn date={row} /> */}
            {fileInfo?.project?._is_table_state && (
                <td className='text-end'>
                    <BalanceColumn {...row} updateFileEdit={updateFileEdit} project={project} />
                </td>
            )}
            <DueDateColumn
                notEditable={notEditable}
                type={type}
                row={row}
                updateDeadline={updateDeadline}
                updateProjectLoading={updateProjectLoading}
            />
            <CompletionDateColumn
                notEditable={notEditable}
                row={row}
                updateDeadline={updateDeadline}
                updateProjectLoading={updateProjectLoading}
            />
            <MenuButtonsColumn
                ignored={ignored}
                type={type}
                row={row}
                updateDeadline={updateDeadline}
                updateProjectLoading={updateProjectLoading}
            />
        </tr>
    )
}

function NextActionColumn(props) {
    const isTxDeadline = props.row?._is_tx2 || props.row?._is_tx3
    const [showPopover, setShowPopover] = useState(false)
    const target = useRef(null)

    // Get all deadlines of each type from fileInfo
    const allDeadlines = props.fileInfo?.deadlines || {}

    const isCompleted = !!props.row?.completed_date
    const shouldShowPopover = isTxDeadline && !isCompleted

    // Filter out completed dates from the "all checked" calculation
    const areTx2AllChecked = Object.values(allDeadlines).every(
        (d) => !d?._is_tx2 || d?.is_deleted || d?.completed_date || d?.is_next_action
    )
    const areTx3AllChecked = Object.values(allDeadlines).every(
        (d) => !d?._is_tx3 || d?.is_deleted || d?.completed_date || d?.is_next_action
    )
    const areBothAllChecked = areTx2AllChecked && areTx3AllChecked

    return (
        <td className='text-center pe-0'>
            {!props.notEditable && (
                <>
                    <div
                        ref={target}
                        onMouseEnter={() => shouldShowPopover && setShowPopover(true)}
                        onMouseLeave={() => shouldShowPopover && setShowPopover(false)}
                        style={{ paddingRight: 10 }}
                    >
                        {!isTxDeadline && (
                            <Tooltip
                                content='Mark Next Action'
                                delay={{
                                    show: 450,
                                    hide: 0,
                                }}
                                trigger={['hover', 'focus']}
                            >
                                <Form.Check
                                    checked={props.row?.is_next_action}
                                    onChange={() => {
                                        props.updateDeadline(props.row?._id, {
                                            is_next_action: !props.row?.is_next_action,
                                        })
                                    }}
                                />
                            </Tooltip>
                        )}
                        {isTxDeadline && (
                            <Form.Check
                                checked={props.row?.is_next_action}
                                onChange={() => {
                                    props.updateDeadline(props.row?._id, {
                                        is_next_action: !props.row?.is_next_action,
                                    })
                                }}
                                disabled={isCompleted}
                            />
                        )}
                    </div>
                    <Overlay
                        target={target.current}
                        show={showPopover}
                        placement='right'
                        offset={[0, -10]}
                    >
                        <Popover
                            id='tx-actions-popover'
                            onMouseEnter={() => setShowPopover(true)}
                            onMouseLeave={() => setShowPopover(false)}
                        >
                            <Popover.Body className='p-0'>
                                <div className='d-flex flex-column gap-1'>
                                    <Button
                                        variant='link'
                                        className='p-2 text-start text-secondary border-bottom'
                                        onClick={() => {
                                            props.markAllTxType('tx2', !areTx2AllChecked)
                                            setShowPopover(false)
                                        }}
                                    >
                                        {areTx2AllChecked ? 'Uncheck all TX2' : 'Check all TX2'}
                                    </Button>
                                    <Button
                                        variant='link'
                                        className='p-2 text-start text-secondary border-bottom'
                                        onClick={() => {
                                            props.markAllTxType('tx3', !areTx3AllChecked)
                                            setShowPopover(false)
                                        }}
                                    >
                                        {areTx3AllChecked ? 'Uncheck all TX3' : 'Check all TX3'}
                                    </Button>
                                    <Button
                                        variant='link'
                                        className='p-2 text-start text-secondary'
                                        onClick={() => {
                                            props.markAllTxType('both', !areBothAllChecked)
                                            setShowPopover(false)
                                        }}
                                    >
                                        {areBothAllChecked
                                            ? 'Uncheck all TX2 & TX3'
                                            : 'Check all TX2 & TX3'}
                                    </Button>
                                </div>
                            </Popover.Body>
                        </Popover>
                    </Overlay>
                </>
            )}
        </td>
    )
}

function DateTypeColumn({ notEditable, row, fileInfo, type, updateDeadline }) {
    const furnishingDateSuffix = formatDate(row?.furnishing_date, 'MMMM YYYY')

    return (
        <td className='no-wrap ps-2'>
            <div className='d-flex align-items-center'>
                <div className='d-flex flex-column align-items-start'>
                    <div
                        className={classnames({
                            'text-dark fw-bold': !notEditable && !row?.completed_date,
                            'text-muted-2': notEditable,
                        })}
                    >
                        {row?.custom_label ?? type?.label}
                        {fileInfo?.project?._is_table_state && row?.furnishing_date && (
                            <span className='ms-1 fw-normal'>- {furnishingDateSuffix}</span>
                        )}
                        {row?.deadline_source == 'CUSTOM' && (
                            <EditCustomDate
                                row={row}
                                customLabel={row?.custom_label ?? type?.label}
                                onSave={(id, udpate) => {
                                    updateDeadline(id, udpate)
                                }}
                            />
                        )}
                    </div>
                    <LinkTaskModal
                        row={row}
                        notEditable={notEditable}
                        type={type}
                        onSave={(id, task_id) => {
                            updateDeadline(id, { task_id: task_id })
                        }}
                    />
                </div>
                {/* {type?.tooltip?.length > 0 && (
                    <Tooltip content={type?.tooltip}>
                        <div className='fal fa-info-circle text-muted ms-auto' />
                    </Tooltip>
                )} */}
                {row?.tooltip?.length > 0 && (
                    <Tooltip content={row?.tooltip}>
                        <div className='fal fa-info-circle text-muted ms-auto' />
                    </Tooltip>
                )}
            </div>
        </td>
    )
}

function EditCustomDate({ row, customLabel, onSave }) {
    const [hover, setHover] = useState(false)
    const editCustomDate = useModalState(false)

    return (
        <>
            <Tooltip content={'Click to Edit'}>
                <Badge
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    soft
                    bg='info'
                    className='ms-2 cursor-pointer'
                    onClick={editCustomDate.show}
                >
                    Custom
                    {row?.is_internal_only && ' - NCS Only'}
                    {hover && <span className='ms-1 far fa-pen' />}
                </Badge>
            </Tooltip>
            <AddEditDateModal
                edit={row}
                show={editCustomDate.isOpen}
                onHide={editCustomDate.hide}
                customLabel={customLabel}
                onSave={onSave}
            />
        </>
    )
}

export function StatusColumn({ date }) {
    let variant,
        label = date?.due_date
            ? 'Due ' + dayjs().to(dayjs(date?.due_date).set('hour', 17))
            : 'No Date',
        soft = false
    //

    const today = dayjs()
    const dueDate = dayjs(date?.due_date)
    const daysUntilDue = dueDate.diff(today, 'day', true)

    if (!date?.due_date && date?.deadline_status != 'NA' && date?.deadline_status != 'IGNORED') {
        variant = 'dark'
        label = 'Incomplete'
        soft = true
    } else if (date?.deadline_status == 'IGNORED') {
        variant = 'secondary'
        label = 'Ignored'
        soft = true
    } else if (date?.deadline_input == 'INPUTONLY') {
        variant = 'secondary'
        label = 'Input Only'
        soft = true
    } else if (date?.deadline_status == 'NA') {
        variant = 'secondary'
        label = 'N/A'
        soft = true
    } else if (!!date.completed_date) {
        variant = 'primary'
        label = 'Completed'
    } else if (daysUntilDue <= 0 && daysUntilDue > -1) {
        variant = 'danger'
        label = 'Today'
    } else if (daysUntilDue <= 1 && daysUntilDue > 0) {
        variant = 'danger'
        label = 'Tomorrow'
    } else if (daysUntilDue <= -1 && daysUntilDue > -2) {
        variant = 'danger'
        label = 'Yesterday'
    } else if (daysUntilDue < -32) {
        variant = 'danger'
        soft = true
    } else if (daysUntilDue <= 7) {
        variant = 'danger'
    } else if (daysUntilDue <= 28) {
        variant = 'warning'
    } else if (!isNaN(daysUntilDue)) {
        variant = 'dark'
        soft = true
    } else {
        variant = 'dark'
        label = 'Incomplete'
        soft = true
    }

    return (
        <td className='text-center'>
            {date?.deadline_input !== 'DUEONLY' &&
                date?.deadline_input !== 'OVERRIDDEN-DUEONLY' &&
                date?.deadline_input !== 'INPUTONLY' && (
                    <Badge pill bg={variant} soft={soft}>
                        {label}
                    </Badge>
                )}
        </td>
    )
}

function BalanceColumn({
    _is_multi_furnishing,
    furnishing_balance,
    _id,
    _furnishings,
    updateFileEdit,
    project,
}) {
    if (_is_multi_furnishing || _is_multi_furnishing == 1) {
        return (
            <BalanceModal
                balance={furnishing_balance}
                deadlineId={_id}
                furnishings={_furnishings}
                update={updateFileEdit}
                project={project}
            />
        )
    }

    return <></>
}

function DueDateColumn(props) {
    return (
        <td className='position-relative'>
            {!props.notEditable &&
            props.row.deadline_input != 'DONEONLY' &&
            (props.type?.deadline_input === 'OVERRIDDEN' ||
                props.type?.deadline_input === 'ENTERED' ||
                props.type?.deadline_input === 'DUEONLY' ||
                props.type?.deadline_input === 'OVERRIDDEN-DUEONLY' ||
                props.type?.deadline_input === 'INPUTONLY') ? (
                <>
                    <InputGroup
                        className={classnames({
                            'mb-3': props.row?.deadline_input === 'OVERRIDDEN',
                        })}
                    >
                        <Form.Date
                            light
                            value={formatDate(props.row?.due_date)}
                            onChange={([date]) => {
                                console.log('on change date', date)
                                props.updateDeadline(props.row?._id, {
                                    due_date: formatDate(date, 'YYYY-MM-DD'),
                                })
                            }}
                            className={'fs-5 border py-1 input-group-child'}
                            style={{
                                maxWidth: 130,
                                borderBottomLeftRadius:
                                    props.row?.deadline_input === 'OVERRIDDEN' ? 0 : null,
                            }}
                            disabled={props.updateProjectLoading}
                            calendarIconPosition='start'
                        />
                        {(props.type?.deadline_input === 'OVERRIDDEN' ||
                            props.type?.deadline_input === 'OVERRIDDEN-DUEONLY') &&
                            props.row?.due_date !== null && (
                                <Tooltip
                                    content={`Clear Date`}
                                    delay={{
                                        show: 450,
                                        hide: 0,
                                    }}
                                    trigger={['hover', 'click']}
                                >
                                    <Form.Button
                                        size='xs'
                                        icon='far fa-times'
                                        onClick={() => {
                                            props.updateDeadline(props.row?._id, {
                                                due_date: null,
                                                deadline_input: 'OVERRIDDEN',
                                            })
                                        }}
                                        disable={props.updateProjectLoading}
                                    />
                                </Tooltip>
                            )}
                        {(props.row?.deadline_input === 'OVERRIDDEN' ||
                            props.row?.deadline_input === 'OVERRIDDEN-DUEONLY') && (
                            <Tooltip
                                content={`Revert Overridden Date`}
                                delay={{
                                    show: 450,
                                    hide: 0,
                                }}
                                trigger={['hover', 'click']}
                            >
                                <Form.Button
                                    disable={
                                        props.updateProjectLoading ||
                                        props.row?.due_date?.length == 0
                                    }
                                    size='xs'
                                    onClick={() => {
                                        props.updateDeadline(props.row?._id, {
                                            due_date: null,
                                            deadline_input: 'CALCULATED',
                                        })
                                    }}
                                    icon='far fa-rotate-left'
                                />
                            </Tooltip>
                        )}
                        {props.type?.deadline_input === 'ENTERED' &&
                            props.row?.due_date?.length > 0 && (
                                <Form.Button
                                    disable={
                                        props.updateProjectLoading ||
                                        props.row?.due_date?.length == 0
                                    }
                                    size='xs'
                                    onClick={() => {
                                        props.updateDeadline(props.row?._id, { due_date: null })
                                    }}
                                    icon='far fa-xmark'
                                />
                            )}
                    </InputGroup>
                    {(props.row?.deadline_input === 'OVERRIDDEN' ||
                        props.row?.deadline_input === 'OVERRIDDEN-DUEONLY') && (
                        <Badge
                            className='position-absolute'
                            style={{
                                top: 42,
                                left: 12,
                                zIndex: 1,
                                width: 130,
                                fontSize: 9,
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,
                            }}
                            bg='info'
                            soft
                        >
                            Overridden
                        </Badge>
                    )}
                </>
            ) : !props.notEditable && props.row?.due_date ? (
                <span className='fs-5 ps-4 text-dark'>{formatDate(props.row?.due_date)}</span>
            ) : (
                <i className='fal fa-hyphen ps-4 text-muted-2' />
            )}
        </td>
    )
}

function CompletionDateColumn(props) {
    return (
        <td>
            {!props.notEditable &&
                props.row?.deadline_input !== 'DUEONLY' &&
                props.row?.deadline_input !== 'OVERRIDDEN-DUEONLY' &&
                props.row?.deadline_input !== 'INPUTONLY' && (
                    <InputGroup>
                        <Form.Date
                            light
                            onChange={([date]) => {
                                props.updateDeadline(props.row?._id, {
                                    completed_date: formatDate(date, 'YYYY-MM-DD'),
                                })
                            }}
                            value={formatDate(props.row?.completed_date)}
                            className={'fs-5 border py-1 input-group-child'}
                            style={{
                                maxWidth: 130,
                            }}
                            disabled={props.updateProjectLoading}
                            calendarIconPosition='start'
                        />
                        <Tooltip
                            content={`Use Today's Date`}
                            delay={{
                                show: 450,
                                hide: 0,
                            }}
                            trigger={['hover', 'click']}
                        >
                            <Form.Button
                                size='xs'
                                icon='far fa-calendar-day'
                                onClick={() => {
                                    props.updateDeadline(props.row?._id, {
                                        completed_date: formatDate(new Date(), 'YYYY-MM-DD'),
                                    })
                                }}
                                disable={props.updateProjectLoading}
                            />
                        </Tooltip>
                        {props.row?.completed_date?.length > 0 && (
                            <Form.Button
                                size='xs'
                                onClick={() => {
                                    props.updateDeadline(props.row?._id, {
                                        completed_date: null,
                                    })
                                }}
                                disable={props.updateProjectLoading}
                                icon='far fa-times'
                            />
                        )}
                    </InputGroup>
                )}
        </td>
    )
}

function MenuButtonsColumn(props) {
    return (
        <td className='text-right'>
            {!props.row?.is_deleted &&
                !props.row?.completed_date &&
                props.row?.deadline_input !== 'NA' &&
                props.row?.deadline_input !== 'DUEONLY' &&
                props.row?.deadline_input !== 'OVERRIDDEN-DUEONLY' &&
                props.row?.deadline_input !== 'INPUTONLY' && (
                    <Tooltip
                        delay={{
                            show: 450,
                            hide: 0,
                        }}
                        content={!props.ignored ? 'Ignore' : 'Un-Ignore'}
                    >
                        <Form.Button
                            circle
                            ghost
                            variant='secondary'
                            onClick={() => {
                                props.updateDeadline(props.row?._id, {
                                    deadline_status: props.ignored ? 'ACTIVE' : 'IGNORED',
                                })
                            }}
                            icon={`fal fa-${props.ignored ? 'plus' : 'bell-slash'}`}
                            disable={props.updateProjectLoading}
                        />
                    </Tooltip>
                )}
            {props.row?.deadline_source == 'CUSTOM' && (
                <DeleteDateModal
                    row={props.row}
                    type={props.type}
                    onClick={(id, is_deleted) => {
                        props.updateDeadline(id, { is_deleted: is_deleted })
                    }}
                />
            )}

            {/* <DropdownButton
    ghost
    variant='secondary'
    className={'float-end'}
    size='sm'
    noToggleArrow
    circle
    icon={'fas fa-ellipsis-v'}
    >
    <Dropdown.Item
    onClick={() => {
       updateFileEdit({
           deadlines: {
               [row?._id]: {
                   deadline_status:
                       row?.deadline_status ==
                       'IGNORED'
                           ? 'ACTIVE'
                           : 'IGNORED',
               },
           },
       })
    }}
    >
    {row?.deadline_status == 'IGNORED'
       ? 'Activate'
       : 'Ignore'}
    </Dropdown.Item>
    </DropdownButton> */}
        </td>
    )
}

const getDueLabel = (date) => {
    if (!date) return 'No Date'
    if (typeof date?.getMonth !== 'function') {
        date = new Date(date)
        date = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    }

    let now = new Date()
    now = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())

    const differenceInDays = Math.floor((now - date) / (1000 * 3600 * 24))
    const absoluteDifference = Math.abs(differenceInDays)
    const isFuture = differenceInDays < 0

    if (absoluteDifference == 0) {
        return 'Due today'
    }

    if (absoluteDifference == 1) {
        if (isFuture) return 'Due tomorrow'
        else return 'Due yesterday'
    }

    if (absoluteDifference <= 31) {
        if (isFuture) return `Due in ${absoluteDifference} days`
        else return `Due ${absoluteDifference} days ago`
    }

    if (absoluteDifference <= 62) {
        const numWeeks = Math.floor(absoluteDifference / 7)

        if (isFuture) return `Due in ${numWeeks} weeks`
        else return `Due ${numWeeks} ${numWeeks == 1 ? 'week' : 'weeks'} ago`
    }

    if (absoluteDifference < 365) {
        const numMonths = Math.floor(absoluteDifference / 31)

        if (isFuture) return `Due in ${numMonths} ${numMonths == 1 ? 'month' : 'months'}`
        else return `Due ${numMonths} ${numMonths == 1 ? 'month' : 'months'} ago`
    }

    const numYears = Math.floor(absoluteDifference / 365)
    if (isFuture) return `Due in ${numYears} ${numYears == 1 ? 'year' : 'years'}`
    else return `${numYears} ${numYears == 1 ? 'year' : 'years'} ago`
}
