import { useSession } from 'hooks'
import Cookies from 'js-cookie'
import { useProjectSearch, useProjectSearchCounts } from '../api'
import { useEffect, useState, createContext, useContext } from 'react'

const DashboardContext = createContext({})

export const useDashboardQueue = () => useContext(DashboardContext)
export function DashboardQueueProvider({ children }) {
    const employee = Cookies.getJSON('employee')
    const getEmpID = () => employee?.EmpID
    const emp_id = getEmpID()
    const employee_id = emp_id

    // Add pagination state]
    const [queuePagination, setQueuePagination] = useSession('trackerQueuePagination', {
        page: 1,
        limit: 30,
        sort: 'assigned_date',
        sortBy: 'desc',
    })
    const [filterPagination, setFilterPagination] = useSession('trackerFilterPagination', {
        page: 1,
        limit: 30,
        sort: 'assigned_date',
        sortBy: 'desc',
    })

    const startFilters = {
        is_active: [],
        client_id: [],
        project_type: [],
        project_service_level: [],
        current_stage: [],
        is_priority: [],
        state: [],
        employee_id: emp_id,
        created_date: [],
    }
    const startQueueFilters = {
        employee_id,
    }

    const [activeTab, setActiveTab] = useSession('trackerDashboardActiveTab', 'userQueues')
    const [queueFilters, setQueueFilters] = useSession('navQueueFilters')
    const [advancedFilters, setAdvancedFilters] = useSession('navAdvancedFilters', startFilters)
    const [workflowFilter, setWorkflowFilter] = useSession('workflowFilter', false)

    useEffect(() => {
        if (!queueFilters && !!startQueueFilters?.employee_id) {
            setQueueFilters(startQueueFilters)
        }
        if (!advancedFilters) {
            setAdvancedFilters(startFilters)
        }
    }, [queueFilters, advancedFilters, startQueueFilters])

    const filters = activeTab === 'userQueues' ? queueFilters : advancedFilters
    // const projectSearch = useProjectSearch({
    //     ...filters,
    //     page,
    //     limit,
    //     sort,
    //     sortBy,
    // })
    const projectCounts = useProjectSearchCounts(filters)

    // Wrap filter setters to reset page when filters change
    const wrappedSetQueueFilters = (newFilters) => {
        setQueueFilters(newFilters)
    }

    const wrappedSetAdvancedFilters = (newFilters) => {
        setAdvancedFilters(newFilters)
    }

    const setFilters =
        activeTab === 'userQueues' ? wrappedSetQueueFilters : wrappedSetAdvancedFilters

    // Add pagination handlers
    const handlePageChange = (newPage) => {
        activeTab === 'userQueues'
            ? setQueuePagination({ ...queuePagination, page: newPage })
            : setFilterPagination({ ...filterPagination, page: newPage })
    }

    const handleViewChange = (newView) => {
        activeTab === 'userQueues'
            ? setQueuePagination({ ...queuePagination, limit: parseInt(newView) })
            : setFilterPagination({ ...filterPagination, limit: parseInt(newView) })
    }

    const handleSortChange = (newSort, newSortBy) => {
        activeTab === 'userQueues'
            ? setQueuePagination({ ...queuePagination, sort: newSort, sortBy: newSortBy })
            : setFilterPagination({ ...filterPagination, sort: newSort, sortBy: newSortBy })
    }

    return (
        <DashboardContext.Provider
            value={{
                activeTab,
                setActiveTab,
                queueFilters,
                setQueueFilters,
                advancedFilters,
                setAdvancedFilters,
                queueFilters,
                setQueueFilters,
                advancedFilters,
                setAdvancedFilters,
                filters,
                setFilters,
                workflowFilter,
                setWorkflowFilter,
                startFilters,
                projectCounts,
                queuePagination,
                setQueuePagination,
                filterPagination,
                setFilterPagination,
                handlePageChange,
                handleViewChange,
                handleSortChange,
            }}
        >
            {children}
        </DashboardContext.Provider>
    )
}
