import { Badge, Button } from 'front'
import { useContext } from 'react'
import { getNcsIcon } from '../../../../utils'
import Sidebar from '../../../front/sidebar/Sidebar'
import EmployeeDropdown from '../../../utils/EmployeeDropdown'
import { useEmployeeBaseLine } from '../api/projectSearch.api'
import { Context } from '../Context/GlobalState'
import { sortBy as sortByLodash } from 'lodash'
import Cookies from 'js-cookie'
const employee = Cookies.getJSON('employee')
const getEmpID = () => employee?.EmpID
import LoadingSpinner from '../../../utils/LoadingSpinner'
import { useProjectSearchCounts } from '../api'
import { useDashboardQueue } from 'tracker/hooks/useDashboardQueue'
const emp_id = getEmpID()

const formatNumber = (num) => {
    return new Intl.NumberFormat().format(num)
}

export default function NavQueue() {
    const {
        queueFilters: filters,
        setQueueFilters: setFilters,
        queuePagination,
        setQueuePagination,
    } = useDashboardQueue()
    const { dropdowns } = useContext(Context)
    const {
        data: baselineCounts = {},
        isLoading: baselineCountsLoading,
        isFetching: baselineCountsFetching,
    } = useEmployeeBaseLine(filters?.employee_id)
    const {
        data: counts = {},
        isLoading: countsLoading,
        isFetching: countsFetching,
    } = useProjectSearchCounts(filters)
    const portalCount = counts?.workflow?.find((i) => i?.id == 'PORTAL')?.count ?? 0
    const desktopCount = counts?.workflow?.find((i) => i?.id == 'DESKTOP')?.count ?? 0
    const importCount = counts?.workflow?.find((i) => i?.id == 'IMPORT')?.count ?? 0

    const changeFilters = (filters) => {
        setFilters(filters)
        setQueuePagination({ ...queuePagination, page: 1 })
    }

    const setPage = (page) => {
        setQueuePagination({ ...queuePagination, page })
    }

    return (
        <>
            <div className='sticky-top bg-white border-bottom'>
                {dropdowns?.employee?.length > 0 && (
                    <div className={'mb-3'}>
                        <EmployeeDropdown
                            employees={sortByLodash(dropdowns?.employee, ['FirstName', 'LastName'])}
                            onChange={(employee_id) => {
                                employee_id ? setFilters({ employee_id }) : setFilters({})
                                setQueuePagination({ ...queuePagination, page: 1 })
                            }}
                            selected={filters?.employee_id}
                            clearsToCurrentUser
                            clearsToCurrentUserLabel='My Queue'
                        />
                    </div>
                )}
                {JSON.stringify(filters) != JSON.stringify({ employee_id: emp_id }) && (
                    <Button onClick={() => setFilters({ employee_id: emp_id })} className='w-100 '>
                        <span className={'far fa-filter-circle-xmark'} /> Remove Filters
                    </Button>
                )}
                <div className='p-3 pb-2 d-flex justify-content-between fw-bold'>
                    <strong>Total</strong>
                    <div className='d-flex align-items-center gap-1'>
                        {(countsLoading || countsFetching) && <LoadingSpinner />}
                        <strong>{counts?.all > 0 && formatNumber(counts?.all)}</strong>
                    </div>
                </div>
            </div>
            <Sidebar.Nav pills>
                <Sidebar.NavHeader className='mt-1 d-flex justify-content-between'>
                    <span>Workflow</span>
                </Sidebar.NavHeader>
                {!baselineCountsLoading && (
                    <>
                        {/* <Sidebar.NavLink
                            active={filters?.workflow === 'DESKTOP'}
                            onClick={() => {
                                setFilters((current) => {
                                    let filters = { ...current }
                                    if (filters?.workflow == 'DESKTOP') {
                                        delete filters.workflow
                                    } else {
                                        filters.workflow = 'DESKTOP'
                                    }
                                    return filters
                                })
                            }}
                            icon='fal fa-desktop'
                        >
                            <div className={'flex-fill d-flex justify-content-between'}>
                                <div>Desktop</div>
                                {desktopCount != 0 && (
                                    <Badge className='d-block' bg='primary' pill>
                                        {formatNumber(desktopCount)}
                                    </Badge>
                                )}
                            </div>
                        </Sidebar.NavLink> */}
                        <Sidebar.NavLink
                            active={filters?.workflow === 'IMPORT'}
                            onClick={() => {
                                setFilters((current) => {
                                    let filters = { ...current }
                                    if (filters?.workflow == 'IMPORT') {
                                        delete filters.workflow
                                    } else {
                                        filters.workflow = 'IMPORT'
                                    }
                                    return filters
                                })
                                setPage(1)
                            }}
                            icon='fal fa-download'
                        >
                            <div className={'flex-fill d-flex justify-content-between'}>
                                <div>Import</div>
                                {importCount != 0 && (
                                    <Badge className='d-block' bg='primary' pill>
                                        {formatNumber(importCount)}
                                    </Badge>
                                )}
                            </div>
                        </Sidebar.NavLink>

                        <Sidebar.NavLink
                            active={filters?.workflow == 'PORTAL'}
                            onClick={() => {
                                setFilters((current) => {
                                    let filters = { ...current }
                                    if (filters?.workflow == 'PORTAL') {
                                        delete filters.workflow
                                    } else {
                                        filters.workflow = 'PORTAL'
                                    }
                                    return filters
                                })
                                setPage(1)
                            }}
                            icon='fal fa-helmet-safety'
                        >
                            <div className={'flex-fill d-flex justify-content-between'}>
                                <div>Portal</div>
                                {portalCount != 0 && (
                                    <Badge className='d-block' bg='primary' pill>
                                        {formatNumber(portalCount)}
                                    </Badge>
                                )}
                            </div>
                        </Sidebar.NavLink>
                    </>
                )}

                <Sidebar.Divider />
                <Sidebar.NavHeader className='mt-4 d-flex justify-content-between'>
                    <span>Stage</span>
                </Sidebar.NavHeader>
                {(baselineCounts?.current_stage ?? [])
                    ?.sort((a, b) => {
                        const stageA = dropdowns?.['task.stage']?.find(
                            (stage) => stage?.code === a?.id
                        )
                        const stageB = dropdowns?.['task.stage']?.find(
                            (stage) => stage?.code === b?.id
                        )
                        return stageA?.sort - stageB?.sort
                    })
                    ?.map((queue, i) => {
                        const count =
                            counts?.current_stage?.find((i) => i?.id == queue?.id)?.count ?? 0
                        const baseLineCount =
                            baselineCounts?.current_stage?.find((i) => i?.id == queue?.id)?.count ??
                            0
                        const selected = filters?.current_stage == queue?.id
                        return (
                            <Sidebar.NavLink
                                key={i}
                                active={selected}
                                icon={getNcsIcon({
                                    dept: 'nml',
                                    category: 'taskStage',
                                    value: queue?.id,
                                    fallback: 'folder',
                                })}
                                onClick={() => {
                                    setFilters((current) => {
                                        let filters = { ...current }
                                        if (selected) {
                                            delete filters.current_stage
                                        } else {
                                            filters.current_stage = [queue?.id]
                                        }
                                        return filters
                                    })
                                    setPage(1)
                                }}
                                className='d-block'
                            >
                                <div className={'w-100 d-flex justify-content-between'}>
                                    <div className='text-capitalize'>
                                        {queue.name.toLowerCase()}
                                    </div>
                                    <Badge
                                        className='d-block'
                                        bg={count > 0 ? 'primary' : 'secondary'}
                                        pill
                                        soft={count > 0 ? false : true}
                                    >
                                        {count === 0 && baseLineCount > 0
                                            ? formatNumber(baseLineCount)
                                            : formatNumber(count)}
                                    </Badge>
                                </div>
                            </Sidebar.NavLink>
                        )
                    })}

                <Sidebar.Divider />
                <Sidebar.NavHeader className='mt-4 d-flex justify-content-between'>
                    <span>Tasks</span>
                </Sidebar.NavHeader>
                {(baselineCounts?.task_category ?? [])?.map((queue, i) => {
                    const selected = filters?.task_category == queue?.id
                    const count = counts?.task_category?.find((i) => i?.id == queue?.id)?.count ?? 0
                    const baseLineCount =
                        baselineCounts?.task_category?.find((i) => i?.id == queue?.id)?.count ?? 0
                    return (
                        <Sidebar.NavLink
                            key={i}
                            active={selected}
                            icon={getNcsIcon({
                                dept: 'nml',
                                category: 'taskCategory',
                                value: queue?.id,
                                fallback: 'folder',
                            })}
                            onClick={() => {
                                setFilters((current) => {
                                    let filters = { ...current }
                                    if (selected) {
                                        delete filters.task_category
                                    } else {
                                        filters.task_category = queue?.id
                                    }
                                    return filters
                                })
                                setPage(1)
                            }}
                            className='d-block'
                        >
                            <div className={'w-100 d-flex justify-content-between'}>
                                <div className=''>{queue.name}</div>
                                <Badge
                                    className='d-block'
                                    bg={count > 0 ? 'primary' : 'secondary'}
                                    pill
                                    soft={count > 0 ? false : true}
                                >
                                    {count === 0 && baseLineCount > 0
                                        ? formatNumber(baseLineCount)
                                        : formatNumber(count)}
                                </Badge>
                            </div>
                        </Sidebar.NavLink>
                    )
                })}

                <Sidebar.Divider />
                <Sidebar.NavHeader className='mt-4 d-flex justify-content-between'>
                    <span>Priority</span>
                </Sidebar.NavHeader>
                {(baselineCounts?.is_priority ?? [])?.map((queue, i) => {
                    if (queue?.id?.length == 0) return
                    const selected = JSON.stringify(filters?.is_priority) == `[${queue?.id}]`
                    const count =
                        counts?.is_priority?.find((i) => String(i?.id) === String(queue?.id))
                            ?.count ?? 0
                    const baseLineCount =
                        baselineCounts?.is_priority?.find(
                            (i) => String(i?.id) === String(queue?.id)
                        )?.count ?? 0
                    return (
                        <Sidebar.NavLink
                            key={i}
                            active={selected}
                            icon={queue.name == '0' ? 'fal fa-play' : 'fal fa-forward'}
                            onClick={() => {
                                setFilters((current) => {
                                    let filters = { ...current }
                                    if (selected) {
                                        delete filters.is_priority
                                    } else {
                                        filters.is_priority = [queue?.id]
                                    }
                                    return filters
                                })
                                setPage(1)
                            }}
                            className='d-block'
                        >
                            <div className={'w-100 d-flex justify-content-between'}>
                                <div className=''>
                                    {queue.name == '0'
                                        ? 'Standard'
                                        : queue.name == '1'
                                        ? 'Priority'
                                        : 'All'}
                                </div>
                                <Badge
                                    className='d-block'
                                    bg={count > 0 ? 'primary' : 'secondary'}
                                    pill
                                    soft={count > 0 ? false : true}
                                >
                                    {count === 0 && baseLineCount > 0
                                        ? formatNumber(baseLineCount)
                                        : formatNumber(count)}
                                </Badge>
                            </div>
                        </Sidebar.NavLink>
                    )
                })}

                <Sidebar.Divider />
                <Sidebar.NavHeader className='mt-4 d-flex justify-content-between'>
                    <span>Created</span>
                </Sidebar.NavHeader>
                {baselineCounts?.created_date?.map((queue, i) => {
                    if (queue?.id?.length == 0) return
                    const selected = JSON.stringify(filters?.created_date) == `[${queue?.id}]`
                    const count = counts?.created_date?.find((i) => i?.id == queue?.id)?.count ?? 0
                    const baseLineCount =
                        baselineCounts?.created_date?.find((i) => i?.id == queue?.id)?.count ?? 0
                    return (
                        <Sidebar.NavLink
                            key={i}
                            active={selected}
                            icon={
                                'fal fa-' +
                                getNcsIcon({
                                    dept: 'nml',
                                    category: 'createdDate',
                                    value: queue?.id,
                                    fallback: 'folder',
                                })
                            }
                            onClick={() => {
                                setFilters((current) => {
                                    let filters = { ...current }
                                    if (selected) {
                                        delete filters.created_date
                                    } else {
                                        filters.created_date = [queue?.id]
                                    }
                                    return filters
                                })
                                setPage(1)
                            }}
                            className='d-block'
                        >
                            <div className={'w-100 d-flex justify-content-between'}>
                                <div className=''>{queue.name}</div>
                                <Badge
                                    className='d-block'
                                    bg={count > 0 ? 'primary' : 'secondary'}
                                    pill
                                    soft={count > 0 ? false : true}
                                >
                                    {count === 0 && baseLineCount > 0
                                        ? formatNumber(baseLineCount)
                                        : formatNumber(count)}
                                </Badge>
                            </div>
                        </Sidebar.NavLink>
                    )
                })}
            </Sidebar.Nav>
        </>
    )
}
