import dayjs from 'dayjs'
import { Badge, Button, Card, Dropdown, DropdownButton, Offcanvas } from 'front'
import { useModalState, useTheme } from 'hooks'
import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'
import { Alert, ButtonGroup, Col, Row, Modal } from 'react-bootstrap'
import {
    useCreatePacket,
    useProject,
    useRevertPacketToDraft,
    useSubmitPacketForPrint,
    useSubmitPacketForReview,
    useTrackerDropdowns,
    useUpdateServiceWorkflow,
} from 'tracker/api'
import { ProjectContext } from 'tracker/Context/ProjectContext'
import CancelPrintModal from './components/CancelPrintModal'
import DeletePacketModal from './components/DeletePacketModal'
import InitialTemplatePicker from './components/InitialTemplatePicker'
import { PacketFurnishings } from './components/PacketFurnishings'
import { PrintDate } from './components/PrintDate'
import { Recipients } from './components/Recipients'
import Documents from './Documents'
import { usePacketForm } from './hooks/usePacketForm'
import { Tooltip } from '@/components/utils'
import AddEditNoteModal from 'tracker/Utils/Notes/AddEditNoteModal'
import { useSelectedTags } from 'tracker/Utils/Notes/useNotes'
import { PacketStatusBadge } from './PacketStatusBadge'
import { useCompletePacketElectronically, useDeletePacket } from 'tracker/api/docgen.api'
import { OptionsCard } from './components/OptionsCard'

export default function PacketOverview({
    projectId,
    taskId,
    currentEmployeeId,
    task,
    packetId: packetIdProp,
    popover = false,
    show = false,
    onHide,
    onMinimize,
    onPacketCreate,
    onDeletePacket = () => {},
}) {
    useEffect(() => {
        setPacketId(packetIdProp)
    }, [packetIdProp])
    const [packetId, setPacketId] = useState(packetIdProp)
    const { data: fileInfo } = useProject(
        { projectId },
        {
            enabled: !!projectId && show,
        }
    )
    const { canEdit } = useContext(ProjectContext)
    const [packetCreateError, setPacketCreateError] = useState(null)

    const packet = usePacketForm(projectId, taskId, packetId, {
        isModalOpen: show,
    })
    const canEditPacket =
        ((packetId && ['DRAFT', 'PENDING REVIEW'].includes(packet?.status)) || !packetId) && canEdit
    const Wrapper = popover ? Offcanvas : 'div'
    const wrapperProps = popover
        ? {
              show,
              onHide: onMinimize,
              placement: 'bottom',
              style: { height: '75vh' },
          }
        : {}
    const Header = popover ? Offcanvas.Header : DefaultPacketHeader
    const Body = popover ? Offcanvas.Body : 'div'
    const showTemplatePicker = !packetId && !packet?.documents.find((doc) => !!doc?.template_id)

    return (
        <Wrapper {...wrapperProps}>
            <Header className='d-flex justify-content-between align-items-center'>
                <div>
                    <span className='fs-4 fw-bold text-dark'>Packet</span>
                    <PacketStatusBadge status={packet?.status} className='ms-2 text-uppercase' />
                </div>
                <PacketActionButtons
                    packetLoading={packet.isLoading}
                    status={packet?.status}
                    projectId={projectId}
                    taskId={taskId}
                    task={task}
                    packet={packet}
                    currentEmployeeId={currentEmployeeId}
                    hidePopover={onHide}
                    minimizePopover={onMinimize}
                    onPacketCreate={(id) => setPacketId(id)}
                    onDeletePacket={onDeletePacket}
                    fileInfo={fileInfo}
                    setPacketCreateError={setPacketCreateError}
                />
            </Header>
            {packetCreateError && <PacketCreateErrorAlert error={packetCreateError} />}
            {(fileInfo?.warnings?.contact_missing || fileInfo?.warnings?.contact_error) && (
                <Alert
                    variant='soft-danger'
                    className='d-flex justify-content-center align-items-center'
                >
                    <div>
                        <i className='far fa-circle-exclamation me-3' />
                        There is no contact selected for this file. You will not be able to generate
                        or print a packet until one is selected.
                    </div>
                </Alert>
            )}
            {packet && (
                <Body className='bg-body pb-0 pt-0'>
                    <Row className='d-flex h-100'>
                        {fileInfo?.project?._is_table_state && (
                            <Col
                                sm={'auto'}
                                className='position-relative'
                                style={{ width: '340px' }}
                            >
                                <div
                                    className='position-absolute h-100 w-100 overflow-auto pt-4'
                                    style={{ paddingRight: 24 }}
                                >
                                    <PacketFurnishings
                                        fileInfo={fileInfo}
                                        packet={packet}
                                        canEdit={!packet?.id}
                                    />
                                </div>
                            </Col>
                        )}
                        <Col sm={'auto'} className='flex-grow-1 position-relative'>
                            <div
                                className='position-absolute h-100 w-100 overflow-auto pt-4'
                                style={{ paddingRight: 24, zIndex: 10 }}
                            >
                                {showTemplatePicker ? (
                                    <InitialTemplatePicker packet={packet} />
                                ) : (
                                    <Documents
                                        projectId={projectId}
                                        taskId={taskId}
                                        packet={packet}
                                        canEdit={canEditPacket}
                                    />
                                )}
                                <Recipients
                                    packet={packet}
                                    recipients={packet?.recipients}
                                    updateAllRecipientsPostage={packet.updateAllRecipientsPostage}
                                    updateRecipientPostage={packet.updateRecipientPostage}
                                    selectRecipient={packet.selectRecipient}
                                    canEdit={canEditPacket}
                                    onMinimize={onMinimize}
                                    USOnlyPostageCodes={packet.USOnlyPostageCodes}
                                />
                            </div>
                        </Col>
                        <Col sm={'auto'} className='position-relative' style={{ width: '424px' }}>
                            <div
                                className='position-absolute h-100 w-100 overflow-auto pt-4'
                                style={{ paddingRight: 24 }}
                            >
                                <PrintDate
                                    value={packet?.minMailDate}
                                    onChange={packet?.updateMinMailDate}
                                    canEdit={canEditPacket}
                                    defaultPrintDate={packet?.defaultPrintDate}
                                />
                                <OptionsCard packet={packet} canEdit={canEditPacket} />
                            </div>
                        </Col>
                    </Row>
                </Body>
            )}
        </Wrapper>
    )
}

const DefaultPacketHeader = ({ children, ...rest }) => (
    <Card>
        <Card.Body {...rest}>{children}</Card.Body>
    </Card>
)

function PacketActionButtons({
    packet,
    projectId,
    taskId,
    status,
    hidePopover,
    minimizePopover,
    onPacketCreate,
    onDeletePacket,
    currentEmployeeId,
    task,
    fileInfo,
    setPacketCreateError,
    packetLoading = false,
}) {
    const { data: dropdowns } = useTrackerDropdowns()
    const { closeWindow } = useContext(ProjectContext)
    const prepEmp = task?.stages?.find((s) => s.stage === 'PREPARING')?.employee_id
    const canSubmitForReview = !!task?.stages?.find((s) => s.stage === 'REVIEW')
    const router = useRouter()
    const { query } = router
    const { theme } = useTheme()
    const { canEdit } = useContext(ProjectContext)

    const deletePacketModal = useModalState(false)
    const cancelPrintModal = useModalState(false)
    const noteModal = useModalState(false)
    const selectedTags = useSelectedTags({ task })

    const packetActionConfig = {
        project_id: projectId,
        task_id: taskId,
        employee_id: currentEmployeeId,
    }

    const { mutate: revertPacketToDraft, ...revertPacketToDraftApi } = useRevertPacketToDraft({
        ...packetActionConfig,
        config: {
            onSuccess: hidePopover,
        },
    })

    const { mutate: submitPacketForReview, ...submitPacketForReviewApi } = useSubmitPacketForReview(
        {
            config: {
                onSuccess: () => {
                    hidePopover()
                },
            },
            ...packetActionConfig,
        }
    )
    const { mutate: submitPacketForPrint, ...submitPacketForPrintApi } = useSubmitPacketForPrint({
        config: { onSuccess: hidePopover },
        project_id: projectId,
        task_id: taskId,
    })
    const { mutate: completeEletronically, ...completeEletronicallyApi } =
        useCompletePacketElectronically({
            config: { onSuccess: hidePopover },
            ...packetActionConfig,
        })
    const { mutate: deletePacket, ...deletePacketApi } = useDeletePacket()

    const { mutate: updateWorkflow, isLoading: workflowLoading } = useUpdateServiceWorkflow({
        project_id: projectId,
        task_id: taskId,
    })

    const noRecipients = packet?.recipients?.filter((r) => r?.selected).length === 0
    const showDropdown = ['DRAFT', 'PENDING REVIEW', 'COMPLETED'].includes(status)
    const nextActions = Object.values(fileInfo?.deadlines || {}).filter((d) => d.is_next_action)

    return (
        <div>
            {packet.isLoading && (
                <Badge bg='secondary' soft className='me-2'>
                    <span className='far fa-spinner-third fa-spin' /> Loading...
                </Badge>
            )}
            {!packet?.id && canEdit && (
                <>
                    <Button ghost variant='secondary' onClick={hidePopover} className='me-2'>
                        Cancel
                    </Button>
                    <CreatePacketButton
                        packetLoading={packetLoading}
                        projectId={projectId}
                        fileInfo={fileInfo}
                        taskId={taskId}
                        packet={packet}
                        onPacketCreate={onPacketCreate}
                        setPacketCreateError={setPacketCreateError}
                    />
                </>
            )}
            {status === 'DRAFT' && canEdit && (
                <ButtonGroup>
                    <Tooltip
                        content={
                            fileInfo?.warnings?.contact_missing || fileInfo?.warnings?.contact_error
                                ? 'Assign a contact to this project before proceeding.'
                                : packet?.invalidRecipientsSelected
                                ? 'Deselect invalid recipients'
                                : noRecipients
                                ? 'Please add recipients before queuing for printing.'
                                : null
                        }
                        placement='bottom'
                    >
                        <Button
                            variant={canSubmitForReview ? 'white' : 'primary'}
                            onClick={() => {
                                submitPacketForPrint(packet?.id)
                            }}
                            loading={submitPacketForPrintApi.isLoading}
                            disabled={
                                packet?.invalidRecipientsSelected ||
                                packetLoading ||
                                fileInfo?.warnings?.contact_missing ||
                                fileInfo?.warnings?.contact_error ||
                                noRecipients
                            }
                        >
                            {canSubmitForReview ? 'Skip to Print' : 'Queue for Printing'}
                        </Button>
                    </Tooltip>
                    {canSubmitForReview && (
                        <Button
                            variant='primary'
                            onClick={() => {
                                submitPacketForReview(packet?.id)
                            }}
                            loading={submitPacketForReviewApi.isLoading}
                            disabled={submitPacketForReviewApi.isLoading || packetLoading}
                        >
                            Submit for Review
                        </Button>
                    )}
                </ButtonGroup>
            )}

            {status === 'PENDING REVIEW' && canEdit && (
                <ButtonGroup>
                    <Button
                        variant='white'
                        onClick={() => {
                            noteModal.show()
                        }}
                        loading={revertPacketToDraftApi.isLoading}
                        disabled={revertPacketToDraftApi.isLoading || packetLoading}
                        icon={'far fa-comment-plus'}
                    >
                        {`Send Back w/ Note`}
                    </Button>
                    <Tooltip
                        content={
                            fileInfo?.warnings?.contact_missing || fileInfo?.warnings?.contact_error
                                ? 'Assign a contact to this project before proceeding.'
                                : packet?.invalidRecipientsSelected
                                ? 'Deselect invalid recipients'
                                : noRecipients
                                ? 'Please add recipients before queuing for printing.'
                                : null
                        }
                        placement='bottom'
                    >
                        <Button
                            variant='primary'
                            onClick={() => {
                                submitPacketForPrint(packet?.id)
                            }}
                            loading={submitPacketForPrintApi.isLoading}
                            icon={'far fa-print'}
                            disabled={
                                packet?.invalidRecipientsSelected ||
                                packetLoading ||
                                fileInfo?.warnings?.contact_missing ||
                                fileInfo?.warnings?.contact_error ||
                                noRecipients ||
                                fileInfo?.incoming_changes?.version?.id
                            }
                            style={{ pointerEvents: 'all' }}
                        >
                            Queue for Printing
                        </Button>
                    </Tooltip>
                </ButtonGroup>
            )}
            {status === 'PRINT QUEUED' && canEdit && (
                <Button
                    variant='danger'
                    onClick={cancelPrintModal.show}
                    loading={submitPacketForReviewApi.isLoading}
                    icon={'far fa-print-slash'}
                >
                    Cancel Printing
                </Button>
            )}
            {status === 'DELETED' && canEdit && (
                <Button
                    variant='success'
                    soft
                    onClick={() => {
                        revertPacketToDraft(packet?.id)
                    }}
                    loading={revertPacketToDraftApi.isLoading}
                    icon={'fal fa-arrow-rotate-left'}
                >
                    Restore Packet
                </Button>
            )}

            {showDropdown && canEdit && (
                <DropdownButton
                    variant=''
                    ghost
                    icon='far fa-ellipsis-vertical'
                    className='d-inline-block'
                    noToggleArrow
                >
                    {status === 'PENDING REVIEW' && canEdit && (
                        <Dropdown.Item
                            onClick={() => {
                                revertPacketToDraft(packet?.id)
                            }}
                        >
                            Send Back
                        </Dropdown.Item>
                    )}
                    {['DRAFT'].includes(status) && (
                        <Dropdown.Item
                            onClick={() => {
                                completeEletronically(packet?.id)
                            }}
                            loading={completeEletronicallyApi.isLoading}
                            disabled={
                                packet?.invalidRecipientsSelected ||
                                !(packet?.documents?.length > 0)
                            }
                        >
                            Generate Electronic Copy
                        </Dropdown.Item>
                    )}

                    {['DRAFT', 'PENDING REVIEW', 'COMPLETED'].includes(status) && (
                        <Dropdown.Item onClick={deletePacketModal.show} className='text-danger'>
                            Discard Packet
                        </Dropdown.Item>
                    )}
                </DropdownButton>
            )}

            {(hidePopover || minimizePopover) && (
                <ButtonGroup
                    className='ms-2 position-absolute'
                    style={{
                        top: -17,
                        right: 10,
                        width: 80,
                        boxShadow:
                            'rgb(50 50 93 / 25%) 0px 13px 27px -5px, rgb(0 0 0 / 30%) 0px 8px 16px -13px',
                    }}
                >
                    {minimizePopover && (
                        <Button
                            variant={theme == 'light' ? 'white' : 'light'}
                            size='xs'
                            icon='fas fa-window-minimize'
                            onClick={minimizePopover}
                            style={{ width: '2rem', height: '2rem' }}
                        />
                    )}
                    {hidePopover && (
                        <Button
                            variant={theme == 'light' ? 'white' : 'light'}
                            size='xs'
                            icon='fas fa-xmark'
                            onClick={() => {
                                hidePopover()
                                if (closeWindow) closeWindow()
                            }}
                            style={{ width: '2rem', height: '2rem' }}
                        />
                    )}
                </ButtonGroup>
            )}

            <DeletePacketModal
                packetId={packet?.id}
                show={deletePacketModal.isOpen}
                onHide={deletePacketModal.hide}
                onSuccess={() => {
                    onDeletePacket()
                    hidePopover()
                }}
            />
            <CancelPrintModal
                task={task}
                packetId={packet?.id}
                projectId={projectId}
                taskId={taskId}
                currentEmployeeId={currentEmployeeId}
                show={cancelPrintModal.isOpen}
                onHide={cancelPrintModal.hide}
            />
            <AddEditNoteModal
                fileInfo={fileInfo}
                show={noteModal.isOpen}
                tags={selectedTags}
                cancel={noteModal.hide}
                assigned_emp_id={prepEmp ?? currentEmployeeId}
                onSave={(data) => {
                    deletePacket(packet?.id)
                    if (data.assigned !== currentEmployeeId) {
                        updateWorkflow({
                            employee_id: data.assigned,
                            stage: 'PREPARING',
                            process: 'assign',
                        })
                    }
                    if (hidePopover) hidePopover()
                }}
            />
        </div>
    )
}

function CreatePacketButton({
    packet,
    projectId,
    taskId,
    onPacketCreate,
    setPacketCreateError,
    packetLoading = false,
    fileInfo,
}) {
    const router = useRouter()
    const { query } = router
    const [showDoNotSendWarning, setShowDoNotSendWarning] = useState(false)
    const { mutate: createPacket, ...createPacketApi } = useCreatePacket(projectId, {
        onSuccess: (data) => {
            onPacketCreate(data?.packet?.id)
            setPacketCreateError(null)
        },
        onError: (error) => {
            setPacketCreateError(error)
        },
    })

    function create() {
        const hasDoNotSendRecipient = packet?.recipients?.some((r) => r.selected && r._dnn)
        if (hasDoNotSendRecipient) {
            setShowDoNotSendWarning(true)
        } else {
            createPacketWithoutWarning()
        }
    }

    function createPacketWithoutWarning() {
        createPacket({
            taskId: taskId,
            data: {
                merge: true,
                documents: packet?.documents?.map(({ template_id, docuware_id }) => ({
                    template_id,
                    docuware_id,
                })),
                min_mail_date: packet?.minMailDate
                    ? dayjs(packet.minMailDate).format('YYYY-MM-DD HH:mm:ss')
                    : dayjs().add(1, 'day'),
                recipients: packet?.getRecipientsPostObject(),
                softening_letter_id: packet?.softeningLetterEnabled
                    ? packet?.softeningLetter?.id
                    : null,
                furnishings: packet?.getFurnishingsPostObject(),
                options: packet?.options,
            },
        })
    }

    return (
        <>
            <Tooltip
                content={
                    fileInfo?.warnings?.contact_missing || fileInfo?.warnings?.contact_error
                        ? 'Please select a contact for this project before procceeding.'
                        : packet?.invalidRecipientsSelected
                        ? 'Deselect invalid recipients'
                        : fileInfo?.project?._is_table_state &&
                          !packet?.furnishings?.some((f) => f.selected)
                        ? 'Please select at least one furnishing'
                        : null
                }
                placement='bottom'
            >
                <Button
                    variant='primary'
                    onClick={create}
                    loading={createPacketApi.isLoading}
                    disabled={
                        packet?.invalidRecipientsSelected ||
                        !(packet?.documents?.length > 0) ||
                        packetLoading ||
                        fileInfo?.warnings?.contact_missing ||
                        fileInfo?.warnings?.contact_error ||
                        (fileInfo?.project?._is_table_state &&
                            !packet?.furnishings?.some((f) => f.selected))
                    }
                    style={{ pointerEvents: 'all' }}
                >
                    Merge Documents
                </Button>
            </Tooltip>

            <DoNotSendWarningModal
                show={showDoNotSendWarning}
                onHide={() => setShowDoNotSendWarning(false)}
                onConfirm={() => {
                    setShowDoNotSendWarning(false)
                    createPacketWithoutWarning()
                }}
            />
        </>
    )
}

function DoNotSendWarningModal({ show, onHide, onConfirm }) {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Warning: Do Not Send List</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                One or more selected recipients are on the Do Not Send list. Are you sure you want
                to proceed?
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-between'>
                <Button variant='ghost' onClick={onHide}>
                    Cancel
                </Button>
                <Button variant='danger' onClick={onConfirm}>
                    Proceed Anyway
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const PacketCreateErrorAlert = ({ error }) => (
    <Alert variant='soft-danger mt-3 mx-5'>
        <div className='d-flex'>
            <div className='flex-shrink-0'>
                <i className='fas fa-circle-exclamation' />
            </div>
            <div className='flex-grow-1 ms-2'>
                Merge Documents Error: {error?.response?.data?.errors?.[0]?.message}
            </div>
        </div>
    </Alert>
)
