import FrontLayout from '../../../front/layouts/FrontLayout'
import { useDashboardQueue } from '../hooks/useDashboardQueue.js'
import Dashboard from './Dashboard'
import DashboardNav from './DashboardNav'

export default function Index({ searchModal }) {
    return (
        <FrontLayout>
            <DashboardNav searchModal={searchModal} />
            <Dashboard />
        </FrontLayout>
    )
}
