import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import FileList from 'tracker/dashboard/FileList'
import { useDashboardQueue } from '../../hooks/useDashboardQueue.js'

export default function ProjectListModal({ show, onHide }) {
    const { currentList, isLoading, workflowFilter, activeTab, filters } = useDashboardQueue()

    return (
        <Modal show={show} onHide={onHide} size={'xl'} className=''>
            <Modal.Header closeButton>
                <Modal.Title>File List</Modal.Title>
            </Modal.Header>
            <div className='mt-2'>
                <FileList
                    files={currentList}
                    filters={filters}
                    isLoading={isLoading}
                    fileClick={onHide}
                    card={false}
                />
            </div>
        </Modal>
    )
}
