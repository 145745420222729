import { Collapse } from '@/components/utils'
import { Button, Form } from 'front'
import { useContext, useEffect, useState } from 'react'
import { Alert, Col, Modal, Row } from 'react-bootstrap'
import { useCreateFee } from 'tracker/api'
import { ProjectContext } from 'tracker/Context/ProjectContext'
import { findCurrencyValues } from 'tracker/Utils/findCurrencyValues'
const defaultFeeData = {
    description: 'Additional Service Charge',
    unit_rate: '0.00',
    fee_type: 'CHARGES',
    breakdown: `Covid-19 (Default for for ML/PIL/LOF/SN ONLY - remove for any other service): $25 
Documentation: $
FedEx (to County): $
SOS/Corporate: $
Recording: $
Re-Draft: $`,
}

export default function AddFeeModal({ taskId, show, onHide }) {
    const [feeData, setFeeData] = useState({ ...defaultFeeData })
    const [breakdownFees, setBreakdownFees] = useState('0.00')

    const { projectId } = useContext(ProjectContext)
    const { mutate: createFee, ...createFeeApi } = useCreateFee(
        { projectId, taskId },
        {
            onSuccess: () => {
                hide()
            },
        }
    )

    function hide() {
        onHide()
        setFeeData({ ...defaultFeeData })
    }

    useEffect(() => {
        findFees()
    }, [feeData.breakdown])

    function findFees() {
        let unit_rate = 0
        const fees = findCurrencyValues(feeData.breakdown)
        if (fees.length > 0) {
            fees.forEach((fee) => {
                unit_rate += parseFloat(fee.replace(/[^0-9.-]+/g, ''))
            })
            setBreakdownFees(unit_rate.toFixed(2).toString())
        } else {
            setBreakdownFees('0.00')
        }
    }

    let onChange = ({ target: { name, value } }) => setFeeData({ ...feeData, [name]: value })
    let submit = () => createFee(feeData)

    return (
        <Modal centered show={show} onHide={hide}>
            <Modal.Header closeButton>
                <Modal.Title>Add Additional Charge</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group as={Row} className='mb-3'>
                    <Form.Label column lg={5} className='text-end pe-8'>
                        Description
                    </Form.Label>
                    <Col>
                        <Form.Control
                            value={feeData.description}
                            name='description'
                            onChange={onChange}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className='mb-3'>
                    <Form.Label column lg={5} className='text-end pe-8'>
                        Price
                    </Form.Label>
                    <Col>
                        <Form.InputGroup inputGroupMerge size={'sm'}>
                            <Form.InputGroup.Text prepend className={'ps-2'}>
                                <i className='fal fa-dollar-sign' />
                            </Form.InputGroup.Text>
                            <Form.Control
                                value={feeData.unit_rate}
                                name='unit_rate'
                                onChange={onChange}
                            />
                        </Form.InputGroup>
                    </Col>
                </Form.Group>
                <Collapse title={<Form.Label>Breakdown</Form.Label>}>
                    <Form.Group as={Row} className='mb-3'>
                        <Form.Control
                            as='textarea'
                            rows={6}
                            value={feeData.breakdown}
                            onChange={onChange}
                            name='breakdown'
                            ignoreGlobalDisable={true}
                        />
                    </Form.Group>
                    {breakdownFees > 0 && breakdownFees != feeData.unit_rate && (
                        <div className='text-center w-100'>
                            <Button
                                variant='link'
                                className='p-0'
                                onClick={() => {
                                    setFeeData({ ...feeData, unit_rate: breakdownFees })
                                }}
                            >
                                Update Price to ${breakdownFees}?
                            </Button>
                        </div>
                    )}
                </Collapse>
                {createFeeApi?.isError && (
                    <Alert variant='soft-danger'>
                        <div className='d-flex'>
                            <div className='flex-shrink-0'>
                                <i className='fas fa-circle-exclamation' />
                            </div>
                            <div className='flex-grow-1 ms-2'>Error adding fee</div>
                        </div>
                    </Alert>
                )}
            </Modal.Body>
            <Modal.Footer className='justify-content-between'>
                <Button onClick={hide} ghost variant='secondary' className='me-2'>
                    Cancel
                </Button>
                <Button
                    onClick={submit}
                    icon={createFeeApi?.isLoading ? 'fal fa-spinner-third fa-spin' : null}
                    variant='primary'
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
