import React, { useState } from 'react'
import { DashboardQueueProvider } from 'tracker/hooks/useDashboardQueue'

export const LayoutContext = React.createContext({})

export default function FrontLayout({ children }) {
    const [leftSidebarCollapsed, setLeftSidebarCollapsed] = useState(false)

    return (
        <LayoutContext.Provider value={{ leftSidebarCollapsed, setLeftSidebarCollapsed }}>
            <DashboardQueueProvider>
                <main id='content' role='main' className='main d-flex flex-grow-1 overflow-hidden'>
                    {children}
                </main>
            </DashboardQueueProvider>
        </LayoutContext.Provider>
    )
}
