import { useEffect, useState, useCallback } from 'react'

export const useConfirmExitPage = (enable) => {
    const [isReloading, setIsReloading] = useState(false)
    const confirmExitPagePrompt = useCallback(
        (e) => {
            // Don't show prompt if it's a reload
            if (isReloading) {
                setIsReloading(false)
                return
            }

            // Modern browsers standardize the message shown
            e.returnValue = null
        },
        [isReloading]
    )

    useEffect(() => {
        if (enable) {
            // Listen for reload attempts
            const handleKeyDown = (e) => {
                if ((e.ctrlKey || e.metaKey) && e.key === 'r') {
                    setIsReloading(true)
                }
            }

            window.addEventListener('keydown', handleKeyDown)
            window.addEventListener('beforeunload', confirmExitPagePrompt)

            return () => {
                window.removeEventListener('keydown', handleKeyDown)
                window.removeEventListener('beforeunload', confirmExitPagePrompt)
            }
        }
    }, [enable, confirmExitPagePrompt])
}
