import { useContext } from 'react'
import Tabs from '../../../front/components/Tabs'
import Sidebar from '../../../front/sidebar/Sidebar'
import { Context } from '../Context/GlobalState'
import NavbarInfo from '../Utils/KeyboardShortcuts/NavbarInfo'
import NavSearch from '../Utils/KeyboardShortcuts/NavSearch'
import NavFilters from './NavFilters'
import NavQueue from './NavQueue'
import { Button } from 'front'
import { useQueryClient } from '@tanstack/react-query'
import { ProjectSearch } from 'tracker/api/projectSearch.api'
import AssignedNotePopover from './AssignedNotePopover'
import { useDashboardQueue } from 'tracker/hooks/useDashboardQueue'
export default function DashboardNav({ searchModal }) {
    const { collapsed } = useContext(Context)
    const {
        activeTab,
        setActiveTab,
        filters,
        setQueueFilters,
        setAdvancedFilters,
        workflowFilter,
        setWorkflowFilter,
        isLoading,
        isRefetching,
        startFilters,
    } = useDashboardQueue()
    const queryClient = useQueryClient()

    return (
        <Sidebar>
            <div className='w-100'>
                <Sidebar.Header
                    href='/nml/tracker'
                    title='LienTracker'
                    logo={<span className='fal fa-helmet-safety text-color-nml' />}
                    collapsed={collapsed}
                />
            </div>
            {/* <TrackerLink /> */}
            <div className='my-3'>
                <NavSearch onClick={searchModal?.show} />
            </div>
            <div className='mb-3'>
                <Button
                    size='sm'
                    onClick={() => {
                        queryClient.invalidateQueries({
                            queryKey: ProjectSearch.keys.type(filters),
                        })
                        queryClient.invalidateQueries({
                            queryKey: ProjectSearch.keys.counts(filters),
                        })
                    }}
                    icon={'fal fa-refresh ' + (isRefetching ? 'fa-spin' : '')}
                    className={'w-100'}
                    disabled={isRefetching}
                >
                    {isRefetching ? 'Currently Refreshing' : 'Refresh List'}
                </Button>
            </div>
            <Sidebar.Content>
                <Tabs
                    width='100%'
                    fillWidth
                    variant='segment'
                    activeKey={activeTab}
                    onSelect={(e) => {
                        setActiveTab(e)
                    }}
                >
                    <Tabs.Tab eventKey='userQueues' title={<span className='fal fa-user' />}>
                        <NavQueue
                            setFilters={setQueueFilters}
                            filters={filters}
                            isLoading={isLoading}
                            workflowFilter={workflowFilter}
                            setWorkflowFilter={setWorkflowFilter}
                        />
                    </Tabs.Tab>
                    <Tabs.Tab eventKey='advancedSearch' title={<span className='fal fa-filter' />}>
                        <NavFilters
                            isLoading={isLoading}
                            setFilters={setAdvancedFilters}
                            filters={filters}
                            startFilters={startFilters}
                        />
                    </Tabs.Tab>
                </Tabs>
            </Sidebar.Content>
            <Sidebar.Footer>
                <AssignedNotePopover />
                <NavbarInfo />
            </Sidebar.Footer>
        </Sidebar>
    )
}
