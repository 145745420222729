// Pagination.jsx
import React from 'react'
import { Button } from 'front'

export default function Pagination({ page = 1, view = 15, total = 0, onPageChange, onViewChange }) {
    const totalPages = Math.max(Math.ceil(total / view), 1)
    const defaultView = 15

    function scrollToTop() {
        const tableElement = document.getElementById('sortableTableHeader')
        if (tableElement) {
            tableElement.scrollIntoView({ behavior: 'smooth' })
        }
    }

    function goToNextPage() {
        if (page < totalPages) {
            scrollToTop()
            onPageChange(page + 1)
        }
    }

    function goToPreviousPage() {
        if (page > 1) {
            scrollToTop()
            onPageChange(page - 1)
        }
    }

    function goToFirstPage() {
        if (page !== 1) {
            scrollToTop()
            onPageChange(1)
        }
    }

    function goToLastPage() {
        if (page !== totalPages) {
            scrollToTop()
            onPageChange(totalPages)
        }
    }

    function handleViewChange(e) {
        const newView = parseInt(e.target.value, 10)
        if (!isNaN(newView)) {
            onViewChange(newView)
        }
    }

    function handlePageSelect(e) {
        const newPage = parseInt(e.target.value, 10)
        if (!isNaN(newPage)) {
            scrollToTop()
            onPageChange(newPage)
        }
    }

    // Show row count instead of pagination when less than defaultView rows
    if (total === 0 || total <= defaultView) {
        return (
            <div className='text-muted'>
                Showing {total.toLocaleString()} {total === 1 ? 'row' : 'rows'}
            </div>
        )
    }

    return (
        <div className='row'>
            <div className='col-auto d-flex align-items-center'>
                <Button size='sm' variant='light' disabled={page <= 1} onClick={goToFirstPage}>
                    <span className='fas fa-arrow-to-left' />
                </Button>
                <Button
                    size='sm'
                    variant='light'
                    className='ms-1'
                    disabled={page <= 1}
                    onClick={goToPreviousPage}
                >
                    <span className='fas fa-arrow-left' />
                </Button>
            </div>

            <div className='col-auto d-flex align-items-center'>
                {/* Show current range and total */}
                {((page - 1) * view + 1).toLocaleString()} -{' '}
                {Math.min(page * view, total).toLocaleString()} of {total.toLocaleString()}
            </div>

            <div className='col-auto d-flex align-items-center'>
                <Button
                    size='sm'
                    variant='light'
                    disabled={page >= totalPages}
                    onClick={goToNextPage}
                >
                    <span className='fas fa-arrow-right' />
                </Button>
                <Button
                    size='sm'
                    className='ms-1'
                    variant='light'
                    disabled={page >= totalPages}
                    onClick={goToLastPage}
                >
                    <span className='fas fa-arrow-to-right' />
                </Button>
            </div>

            <div className='col-auto d-flex align-items-center'>Rows:</div>
            <div className='col-auto d-flex align-items-center px-0'>
                <select
                    className='form-select form-select-sm'
                    onChange={handleViewChange}
                    value={view.toString()}
                    style={{ width: '80px' }}
                >
                    <option value='15'>15</option>
                    <option value='30'>30</option>
                    <option value='100'>100</option>
                </select>
            </div>

            <div className='col-auto d-flex align-items-center'>Page:</div>
            <div className='col-auto d-flex align-items-center px-0'>
                <select
                    className='form-select form-select-sm'
                    title='Go to page'
                    onChange={handlePageSelect}
                    value={page.toString()}
                    style={{ width: '80px' }}
                >
                    {Array.from({ length: totalPages }, (_, i) => {
                        const pageNum = (i + 1).toString()
                        return (
                            <option key={pageNum} value={pageNum}>
                                {i + 1}
                            </option>
                        )
                    })}
                </select>
            </div>
        </div>
    )
}
