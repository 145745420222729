import { Badge, Button, Card } from 'front'
import { useContext, useState } from 'react'
import { ProjectContext } from 'tracker/Context/ProjectContext'
import AddEditNoteModal from './AddEditNoteModal'
import { StepNotes } from './StepsNotes'
import { useFilteredNotes, useSelectedTags } from './useNotes'

export default function NoteCard({ title, associate, task }) {
    const { fileInfo } = useContext(ProjectContext)
    const [showAll, setShowAll] = useState(false)
    const [showAddModal, setShowAddModal] = useState(false)
    const filteredNotes = useFilteredNotes({ associate, task })
    const selectedTags = useSelectedTags({ associate, task })

    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title className={'justify-content-between d-flex align-items-center'}>
                        <div>
                            {title ?? 'Notes'}
                            {filteredNotes?.length > 0 && (
                                <Badge bg='secondary' soft className='ms-1'>
                                    {filteredNotes?.length}
                                </Badge>
                            )}
                        </div>
                        <div>
                            {fileInfo?.project?._workflow != 'DESKTOP' && (
                                <Button
                                    size={'sm'}
                                    icon={'far fa-plus'}
                                    onClick={() => {
                                        setShowAddModal(true)
                                    }}
                                >
                                    Add
                                </Button>
                            )}
                        </div>
                    </Card.Title>
                </Card.Header>
                {filteredNotes?.length > 0 && (
                    <Card.Body className={'pb-0'}>
                        <StepNotes
                            notes={filteredNotes?.slice(0, showAll ? filteredNotes?.length : 2)}
                        />
                        {filteredNotes?.length > 2 && (
                            <Button
                                className='w-100 mb-3'
                                onClick={() => {
                                    setShowAll(!showAll)
                                }}
                            >
                                Show {showAll ? 'Less' : 'All'}
                            </Button>
                        )}
                    </Card.Body>
                )}
                <AddEditNoteModal
                    show={showAddModal}
                    tags={selectedTags}
                    cancel={() => {
                        setShowAddModal(false)
                    }}
                />
            </Card>
        </>
    )
}
