import { formatDate, isEmpty } from 'components/utils'
import { Button, Form, Offcanvas } from 'front'
import { useContext, useEffect, useState } from 'react'
import { ProjectContext } from 'tracker/Context/ProjectContext'
import FurnishingDatesEdit from './FurnishingDatesEdit'
import { useProject, useUpdateProject } from 'tracker/api'
import getObjectDifferences from '@/utils/getObjectDifferences'

export default function AllFurnishings({ show, onHide }) {
    const { fileInfo } = useContext(ProjectContext)
    const { data: project } = useProject({ projectId: fileInfo?.project_id })
    const [furnishings, setFurnishings] = useState(project?.furnishings ?? {})
    useEffect(() => {
        if (!isEmpty(getObjectDifferences(furnishings, project?.furnishings))) {
            setFurnishings(project?.furnishings ?? {})
        }
    }, [project])

    const { mutate: updateProject, isLoading: updateProjectLoading } = useUpdateProject(
        {
            projectId: fileInfo?.project_id,
        },
        {
            onSuccess: onHide,
        }
    )

    useEffect(() => {
        if (show) setFurnishings(project?.furnishings ?? {})
    }, [show])

    function save() {
        const filteredFurnishings = Object.values(furnishings)?.filter((f) => !f?.is_deleted)
        //if one of the furnishings is before or after a first/last furnishing date, update the project
        const { first_furnishing_date, last_furnishing_date } = filteredFurnishings.reduce(
            (acc, curr) => {
                const currDate = new Date(curr.date)
                const min = new Date(acc.min)
                const max = new Date(acc.max)
                if (!min || currDate < min) {
                    acc.min = formatDate(currDate)
                }
                if (!max || currDate > max) {
                    acc.max = formatDate(currDate)
                }
                return acc
            },
            {
                first_furnishing_date: null,
                last_furnishing_date: null,
            }
        )

        // Remove the "selected" field from each furnishing
        const updatedFurnishings = Object.fromEntries(
            Object.entries(furnishings).map(([key, value]) => {
                const { selected, ...rest } = value
                return [key, rest]
            })
        )

        updateProject({
            furnishings: updatedFurnishings,
            project: { first_furnishing_date, last_furnishing_date },
        })
    }
    function updateFurnishings({ furnishing, name, value }) {
        setFurnishings((current) => ({
            ...current,
            [furnishing?._id]: { ...furnishing, [name]: value },
        }))
    }

    function updateFurnishingData(furnishing, newFurnishingData) {
        setFurnishings((current) => ({
            ...current,
            [furnishing?._id]: { ...furnishing, ...newFurnishingData },
        }))
    }

    function addFurnishing() {
        const randomId = Math.round(Math.random() * -1000000)
        setFurnishings((current) => ({
            ...current,
            [randomId]: {
                _id: randomId,
                date: null,
                total: 0,
                balance: 0,
                is_paid: false,
                _create: true,
            },
        }))
    }

    const cantSave = !!Object.values(furnishings)?.find((f) => !f?.date)
    return (
        <Offcanvas show={show} onHide={onHide} placement={'end'} style={{ width: 550 }}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Furnishings</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className='d-flex align-content-between flex-wrap'>
                <div className='w-100'>
                    {Object.values(furnishings)?.length === 0 ? (
                        <h2>No Furnishings</h2>
                    ) : (
                        <FurnishingDatesEdit
                            furnishings={Object.values(furnishings)?.filter((f) => !f?.is_deleted)}
                            updateFurnishings={updateFurnishings}
                            updateFurnishingData={updateFurnishingData}
                        />
                    )}
                    <Form.Button className='w-100 mt-3' onClick={addFurnishing}>
                        <span className='far fa-add' /> Add Furnishing
                    </Form.Button>
                </div>
                <div className='w-100'></div>
            </Offcanvas.Body>
            <Offcanvas.Footer className='d-flex justify-content-between align-items-center'>
                <Button ghost variant='secondary' onClick={onHide} disabled={updateProjectLoading}>
                    Cancel
                </Button>
                <Form.Button
                    variant='primary'
                    onClick={save}
                    disable={updateProjectLoading || cantSave}
                    loading={updateProjectLoading}
                >
                    Save
                </Form.Button>
            </Offcanvas.Footer>
        </Offcanvas>
    )
}
