import { EmployeeAvatar, formatDate, Tooltip } from '@/components/utils'
import dayjs, { toNow } from '@/lib/dayjs'
import { useQueryClient } from '@tanstack/react-query'
import classnames from 'classnames'
import SortableTable from 'components/SortableTable'
import { Badge, Button } from 'front'
import { useModalState } from 'hooks'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { useContext, useEffect, useState, useMemo } from 'react'
import { Modal } from 'react-bootstrap'
import AddEditNoteModal from 'tracker/Utils/Notes/AddEditNoteModal'
import { useSelectedTags } from 'tracker/Utils/Notes/useNotes'
import { useTrackerDropdowns } from 'tracker/api'
import { Project } from 'tracker/api/project.api'
import {
    ProjectSearch,
    useImportProject,
    useProjectSearch,
    useProjectSearchCounts,
} from 'tracker/api/projectSearch.api'
import { useReassignTask } from 'tracker/api/task.api'
import { TaskStatusBadge } from 'tracker/file/project/tasks/Tasks'
import { useDashboardQueue } from 'tracker/hooks/useDashboardQueue'
import { toTitleCase } from 'utils'
import NcsLoadingSpinner from 'utils/NcsLoadingSpinner'
import { PortalContext } from '../../../GlobalState'
import { startPath } from '../Context/AxiosSetup'
import ConfirmNotePopover from './ConfirmNotePopover'

// Map frontend sort fields to backend sort fields
const sortFieldMap = {
    // File #
    ncs_lno: 'ncs_lno',
    project_id: 'project_id',

    // Task
    'task.code_label': 'task_code_label', // This maps to service level in backend

    // Deadline
    next_deadline_date: 'next_deadline_date',

    // Status
    'task.current_stage': 'task_current_stage',

    // Assigned
    'task.current_employee_name': 'task_current_employee_name',
    assigned_date: 'assigned_date',

    // Client/Project
    'client.name': 'client_name',

    // State
    state: 'state',

    // Client Ref No
    reference_number: 'name', // Maps to project name in backend

    // Registered
    is_registered_mail: 'is_registered_mail', // Maps to task status in backend

    // Print Date
    'packet_info.min_mail_date': 'packet_min_mail_date', // Maps to task created date in backend

    // Doc/Set-up Date
    'packet_info.document_type': 'packet_document_type', // Maps to service level in backend

    // Client Ref No
    reference_number: 'reference_number', // Maps to project name in backend
}

function generateFilterKey(filters) {
    const sortedFilters = deepSort(filters)
    let filterKey = JSON.stringify(sortedFilters)
    const MAX_KEY_LENGTH = 200
    if (filterKey.length > MAX_KEY_LENGTH) {
        filterKey = filterKey.substring(0, MAX_KEY_LENGTH)
    }
    return filterKey
}

function deepSort(obj) {
    if (Array.isArray(obj)) {
        return obj.map(deepSort).sort()
    } else if (obj !== null && typeof obj === 'object') {
        return Object.keys(obj)
            .sort()
            .reduce((result, key) => {
                result[key] = deepSort(obj[key])
                return result
            }, {})
    }
    return obj
}

export default function FileList(props) {
    const {
        activeTab,
        queueFilters,
        advancedFilters,
        queuePagination,
        setQueuePagination,
        filterPagination,
        setFilterPagination,
    } = useDashboardQueue()

    let sort = queuePagination?.sort
    let sortBy = queuePagination?.sortBy
    let page = queuePagination?.page
    let limit = queuePagination?.limit
    let handlePageChange = (newPage) => {
        activeTab === 'userQueues'
            ? setQueuePagination({ ...queuePagination, page: newPage })
            : setFilterPagination({ ...filterPagination, page: newPage })
    }
    let handleViewChange = (newView) => {
        activeTab === 'userQueues'
            ? setQueuePagination({ ...queuePagination, limit: parseInt(newView), page: 1 })
            : setFilterPagination({ ...filterPagination, limit: parseInt(newView), page: 1 })
    }
    let handleSortChange = (newSort, newSortBy) => {
        activeTab === 'userQueues'
            ? setQueuePagination({ ...queuePagination, sort: newSort, sortBy: newSortBy })
            : setFilterPagination({ ...filterPagination, sort: newSort, sortBy: newSortBy })
    }

    if (activeTab === 'advancedSearch') {
        sort = filterPagination?.sort
        sortBy = filterPagination?.sortBy
        page = filterPagination?.page
        limit = filterPagination?.limit
    }

    // Memoize filters
    const filters = useMemo(
        () => (activeTab === 'userQueues' ? queueFilters : advancedFilters),
        [activeTab, queueFilters, advancedFilters]
    )

    // Memoize filterKey
    const filterKey = useMemo(() => generateFilterKey(filters), [filters])

    const current_stage = filters?.current_stage
    const inReviewQueue = current_stage?.length === 1 && current_stage[0] === 'REVIEW'
    const { data: dropdowns } = useTrackerDropdowns()
    const { employee, changePreference, preferencesLoading, theme } = useContext(PortalContext)
    const router = useRouter()
    const preferences = employee?.preferences?.nml?.dashboard_table

    const [scrollPosition, setScrollPosition] = useState(0)

    // Memoize the mapped sort field
    const projectSearchSort = useMemo(() => sortFieldMap[sort] || sort, [sort])

    const {
        data: files,
        isPending: isLoading,
        isRefetching,
        isFetching,
    } = useProjectSearch({
        ...filters,
        page,
        limit,
        sort: projectSearchSort,
        sortBy,
    })
    const { data: counts } = useProjectSearchCounts(filters)

    function saveCustomColumns(columns) {
        changePreference('nml', 'dashboard_table', columns)
    }

    useEffect(() => {
        // Only update preferences if they don't exist and we're not loading them
        if (!preferences && !preferencesLoading) {
            const defaultPreferences = [
                { option: 'client_project', value: true, label: 'Client/Project' },
                { option: 'state', value: true, label: 'State' },
                { option: 'reference_no', value: true, label: 'Cli Ref No' },
            ]
            changePreference('nml', 'dashboard_table', defaultPreferences)
        }
    }, [preferences, preferencesLoading, changePreference])

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY)
        }

        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    let trackerFileListFilter = 47
    if (typeof document !== 'undefined') {
        trackerFileListFilter = document.getElementById('trackerFileListFilter')?.clientHeight
    }

    const customFile = (file) => (
        <div>
            {!file?.workflow ? (
                <>
                    <Button
                        size='sm'
                        pill
                        variant={file?.is_import_error ? 'danger' : 'soft-secondary'}
                        onClick={() => {
                            // if (selectedFile.project_id !== file?.project_id) {
                            props.showPreviewOffcanvas()
                            props.setSelectedFile(file)
                            // }
                        }}
                        className='shadow-sm'
                        style={{ padding: '5px 10px' }}
                    >
                        <small>
                            {file?.is_import_error ? (
                                <>
                                    <i className='fas fa-circle-exclamation me-2' />
                                    Import Error
                                </>
                            ) : (
                                <div className='px-4'>
                                    <i className='fas fa-cloud-arrow-down me-2' />
                                    Import
                                </div>
                            )}
                        </small>
                    </Button>
                    <small className='d-block text-muted-2 ms-6'>({file?.project_id})</small>
                </>
            ) : (
                <div className='d-flex flex-column'>
                    <div className='d-inline-block' style={{ whiteSpace: 'nowrap' }}>
                        <div className={'flex align-items-center position-relative'}>
                            <Button
                                size='sm'
                                pill
                                variant='secondary'
                                onClick={() => {
                                    props?.fileClick && props.fileClick()
                                    router.push(
                                        `${startPath}/file/overview?project_id=${file?.project_id}`
                                    )
                                }}
                                className={'me-1 position-relative'}
                                style={{ padding: '5px 10px', zIndex: 5 }}
                            >
                                <small className=''>
                                    {file?.ncs_lno ? file.ncs_lno : 'NEW'}{' '}
                                    <i className='ms-1 far fa-angle-right opacity-75' />
                                </small>
                            </Button>
                            <Tooltip content='Preview Project' placement='right'>
                                <Button
                                    size='sm'
                                    pill
                                    variant='white'
                                    onClick={() => {
                                        props.showPreviewOffcanvas()
                                        props.setSelectedFile(file)
                                    }}
                                    className={'position-relative'}
                                    style={{
                                        padding: '5px 10px',
                                        paddingLeft: 35,
                                        zIndex: 4,
                                        marginLeft: -34,
                                    }}
                                >
                                    <span className={'fas fa-eye'} />
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                    <div className='mt-1 ms-2 d-inline-block'>
                        {file?.pending_lock_contact_id && (
                            <Tooltip
                                content={
                                    <>
                                        Being edited by{' '}
                                        {
                                            dropdowns?.employee?.find(
                                                ({ cnt_id }) =>
                                                    cnt_id == file?.pending_lock_contact_id
                                            )?.label
                                        }
                                    </>
                                }
                            >
                                <i className='fas fa-lock text-muted me-2 fa-sm' />
                            </Tooltip>
                        )}
                        {file?.is_incoming_changes && (
                            <Tooltip content={'Incoming changes from OLS'}>
                                <i className='fas fa-cloud-arrow-down text-primary fa-sm me-2' />
                            </Tooltip>
                        )}
                        {file?.packets?.length > 1 && (
                            <Tooltip
                                content={
                                    'Multiple Packets were created for this task, please view all'
                                }
                            >
                                <i className='fas fa-folders text-muted fa-sm me-2' />
                            </Tooltip>
                        )}
                        {file?.is_pending_notes && file?.notes?.length > 0 && (
                            <ConfirmNotePopover className='me-2' notes={file?.notes}>
                                <i className='fas fa-message-check text-muted' />
                            </ConfirmNotePopover>
                        )}
                        {file?.workflow === 'DESKTOP' && (
                            <Tooltip content={'Project in Desktop Tracker'}>
                                <i className='fas fa-desktop text-warning fa-sm me-2' />
                            </Tooltip>
                        )}
                    </div>
                </div>
            )}
        </div>
    )

    const status = (file) => {
        switch (file?.task?.status) {
            case 'NEW':
                return (
                    <div className='mb-0'>
                        <div className={`badge bg-primary text-white`}>New Task</div>
                    </div>
                )
            case 'IN PROGRESS':
                return (
                    <>
                        <div className='mb-0'>
                            <div className={`badge bg-soft-info text-info`}>
                                <span className=''>{toTitleCase(file?.task?.current_stage)}</span>
                            </div>
                        </div>
                    </>
                )
            case 'COMPLETED':
                return (
                    <div className='mb-0'>
                        <span className={`badge bg-primary text-white`}>Task Complete</span>
                    </div>
                )
            default:
                return (
                    <div className='mb-0'>
                        <span className={`badge bg-soft-secondary text-secondary`}>
                            {file?.task?.status}
                        </span>
                    </div>
                )
        }
    }

    const task = (file) => {
        const fileNo = file?.project_id
        const deadline = file?.next_deadline_date
        const today = dayjs()
        const dueDate = dayjs(deadline)
        const daysUntilDue = dueDate.diff(today, 'day', true)
        const level = <span className=''>{toTitleCase(file?.task?.service_level)}</span>

        const relativeDeadline = dayjs(dayjs(deadline).set('hour', 17)).fromNow()

        let displayDeadline
        if (daysUntilDue <= 0 && daysUntilDue > -1) {
            displayDeadline = 'today'
        } else if (daysUntilDue <= 1 && daysUntilDue > 0) {
            displayDeadline = 'tomorrow'
        } else if (daysUntilDue <= -1 && daysUntilDue > -2) {
            displayDeadline = 'yesterday'
        } else {
            displayDeadline = relativeDeadline
        }

        return (
            <div>
                <span className={'d-block fw-bold text-dark mb-0'}>{file?.task?.code_label}</span>
                <span className={'d-block'}>
                    {level}
                    {file?.is_priority && <TaskStatusBadge status={'PRIORITY'} className='ms-2' />}
                </span>
            </div>
        )
    }

    const packet = ({ packet_info }) => {
        const { printed_date, min_mail_date, queued_date } = packet_info ?? {}
        const printedStatus = toNow(printed_date)
        let mailStatus = toNow(min_mail_date)
        const mailDate = dayjs(min_mail_date)
        const today = dayjs().startOf('day')
        const mailDateStart = mailDate.startOf('day')

        if (mailDateStart.isSame(today) && mailDate.hour() === 12 && mailDate.minute() === 0) {
            mailStatus = 'Noon'
        }
        const isDeadlineUrgent = isUrgent(queued_date, 1)

        return (
            <div>
                {min_mail_date && (
                    <span className={'d-block mb-1'}>{formatDate(min_mail_date)}</span>
                )}
                <span className={'d-block'}>
                    <Badge
                        soft
                        bg={
                            printed_date
                                ? 'primary'
                                : !queued_date || min_mail_date
                                ? 'secondary'
                                : isDeadlineUrgent
                                ? 'danger'
                                : 'secondary'
                        }
                        className='text-capitalize'
                    >
                        {printed_date
                            ? printedStatus
                            : min_mail_date
                            ? mailStatus
                            : 'No Print Date'}
                    </Badge>
                </span>
            </div>
        )
    }

    const docSetupDate = ({ packet_info }) => {
        if (!packet_info)
            return (
                <Badge soft bg='secondary'>
                    No Packet
                </Badge>
            )
        const { queued_date, document_type } = packet_info ?? {}

        return (
            <div className='d-flex flex-column'>
                <div>{formatDate(queued_date)}</div>
                <hr className='my-2' />
                <div>{document_type}</div>
            </div>
        )
    }

    const deadline = (file) => {
        const fileNo = file?.project_id
        const deadline = file?.next_deadline_date
        const displayDeadline = toNow(deadline)
        const isDeadlineUrgent = isUrgent(file?.next_deadline_date)

        return (
            <div>
                {file?.next_deadline_label ? (
                    <small className={'d-block fw-bold text-dark mb-0 ms-1 mb-1'}>
                        {file?.next_deadline_label}
                    </small>
                ) : (
                    <Tooltip content='No linked date. Showing next available deadline instead.'>
                        <small
                            className={'d-block text-muted fst-italic mb-0 ms-1 mb-1'}
                            style={{ width: 'fit-content' }}
                        >
                            Deadline estimated <i className='fad fa-circle-info fa-sm ms-1' />
                        </small>
                    </Tooltip>
                )}
                <span className={'d-block'}>
                    {file?.next_deadline_date && file?.next_deadline_date != '0000-00-00' ? (
                        <>
                            <span
                                className={classnames('', {
                                    'badge bg-soft-secondary text-secondary': !isDeadlineUrgent,
                                    'badge bg-soft-danger text-danger': isDeadlineUrgent,
                                })}
                            >
                                Due {displayDeadline}
                            </span>{' '}
                            <span className='smaller text-muted fw-normal ms-1'>
                                {' '}
                                {formatDate(
                                    file?.next_deadline_date,
                                    'MMM D, YYYY',
                                    '--/--/----'
                                )}{' '}
                                {!file?.next_deadline_label && '(est)'}
                            </span>
                        </>
                    ) : (
                        <small className='text-muted fst-italic'>No deadline</small>
                    )}
                </span>
            </div>
        )
    }

    return (
        <>
            {isLoading && (
                <h3
                    className='position-fixed d-flex align-items-center justify-content-center'
                    style={{
                        top: `calc(${scrollPosition}px + 50vh)`,
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 1000,
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        padding: '20px',
                        borderRadius: '8px',
                    }}
                >
                    <NcsLoadingSpinner height={50} width={50} className='me-3' /> Getting Files...
                </h3>
            )}
            {isRefetching && (
                <Badge
                    bg={theme == 'dark' ? 'secondary' : 'dark'}
                    className='position-fixed'
                    style={{ zIndex: 1000, top: 70, right: 5 }}
                >
                    <h3 className='text-white m-0 p-0'>
                        <i className='fa-duotone fa-spinner-third me-2 fa-spin' />
                        Refetching List
                    </h3>
                </Badge>
            )}
            <SortableTable
                filterKey={filterKey}
                headers={[
                    {
                        name: 'File #',
                        custom: customFile,
                        sortField: 'ncs_lno',
                        searchField: ['ncs_lno', 'project_id'],
                    },
                    {
                        name: 'Task',
                        custom: task,
                        sortField: 'task.code_label',
                        searchField: ['task.service_level', 'task.code_label'],
                    },
                    {
                        name: 'Deadline',
                        sortField: 'next_deadline_date',
                        searchField: 'next_deadline_date',
                        custom: deadline,
                    },
                    !inReviewQueue && {
                        name: 'Status',
                        custom: status,
                        rowClassName: 'text-center',
                        className: 'text-center',
                        sortField: 'task.current_stage',
                        searchField: 'task.current_stage',
                    },
                    {
                        name: 'Assigned ' + (inReviewQueue ? 'To' : 'Date'),
                        custom: (file) => (
                            <Assigned
                                file={file}
                                filters={props?.filters}
                                reassignModal={props?.reassignModal}
                            />
                        ),
                        sortField: inReviewQueue ? 'task.current_employee_name' : 'assigned_date',
                        searchField: 'assigned_date',
                    },
                    preferences?.client_project?.value != 0 && {
                        name: 'Client / Project',
                        value: [
                            {
                                custom: ({ client }) => (
                                    <div className={'d-block h5 mb-0'}>
                                        {client?.class?.length > 0 && client?.class + ' - '}
                                        {client?.name}
                                    </div>
                                ),
                            },
                            { value: 'name', className: 'd-block mt-1 fs-5' },
                        ],
                        noHr: true,
                        sortField: 'client.name',
                        searchField: ['client.name', 'name', 'client.class'],
                    },
                    preferences?.state?.value != 0 && { name: 'State', value: 'state' },
                    !inReviewQueue &&
                        preferences?.reference_no?.value != 0 && {
                            name: 'Client Ref No',
                            value: 'reference_number',
                            sortField: 'reference_number',
                            searchField: 'reference_number',
                        },
                    inReviewQueue && {
                        name: 'Registered',
                        custom: (file) =>
                            file?.is_registered_mail && (
                                <span className='far fa-check-circle text-success' />
                            ),
                        rowClassName: 'text-center',
                        sortField: 'is_registered_mail',
                        searchField: 'is_registered_mail',
                    },
                    inReviewQueue && {
                        name: 'Print Date',
                        custom: packet,
                        sortField: 'packet_info.min_mail_date',
                        searchField: 'packet_info.min_mail_date',
                    },
                    inReviewQueue && {
                        name: 'Doc / Set-up Date',
                        value: [
                            { value: 'packet_info.document_type' },
                            { value: 'packet_info.created_date', date: true },
                        ],
                        sortField: 'packet_info.document_type',
                        searchField: ['packet_info.document_type', 'packet_info.created_date'],
                    },
                ]}
                search
                info={files ?? []}
                skeleton={isLoading}
                startSort={
                    activeTab === 'userQueues' ? queuePagination?.sort : filterPagination?.sort
                }
                startSortBy={
                    activeTab === 'userQueues' ? queuePagination?.sortBy : filterPagination?.sortBy
                }
                onSortChange={handleSortChange}
                serverSideSort={true}
                card={props?.card ?? true}
                cardTitle='Projects'
                stickyHeaders
                stickyHeaderOffset={trackerFileListFilter ? trackerFileListFilter - 1 : 0}
                customizeColumn
                saveCustomColumns={saveCustomColumns}
                customizeColumnOptions={preferences ? Object.values(preferences) : []}
                searchPlaceholder='Filter Results by Keyword'
                searchIcon='far fa-bars-filter'
                loading={isLoading}
                paginationOptions={{
                    page,
                    view: limit,
                    total: counts?.all ?? 0,
                    onPageChange: handlePageChange,
                    onViewChange: handleViewChange,
                }}
                all={false}
            />
        </>
    )
}

function ImportModal({ file, filters }) {
    const [show, setShow] = useState(false)
    const { mutate, isLoading } = useImportProject({ project_id: file?.project_id, filters })

    function onClose() {
        setShow(false)
    }

    return (
        <>
            <Button
                variant={'link'}
                className={'p-0 m-0'}
                onClick={() => {
                    setShow(true)
                }}
            >
                Import
                <span className='fal fa-download ms-2' />
            </Button>
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Import into Portal</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to import {file?.name}?</Modal.Body>
                <Modal.Footer className='d-flex justify-content-between '>
                    <Button onClick={onClose} ghost>
                        Cancel
                    </Button>
                    <Button
                        onClick={mutate}
                        variant='primary'
                        loading={isLoading}
                        disabled={isLoading}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export function Assigned({ file, filters }) {
    const queryClient = useQueryClient()
    const selectedTags = useSelectedTags({ task: file?.task })
    const reassignModal = useModalState(false)
    const [empId, setEmpId] = useState(file?.task?.current_employee_id ?? null)
    const { mutate, isLoading } = useReassignTask(
        { taskId: file?.task?.id, projectId: file?.project_id, empId },
        {
            onSuccess: () => {
                reassignModal.hide()
                queryClient.invalidateQueries({
                    queryKey: ProjectSearch.keys.type(filters),
                })
                queryClient.invalidateQueries({
                    queryKey: ProjectSearch.keys.baseline(filters.employee_id),
                })
                queryClient.invalidateQueries({
                    queryKey: Project.keys.project(file?.project_id),
                })
            },
        }
    )

    const employee = Cookies.getJSON('employee')
    const cnt_id = parseInt(employee?.cnt_id)
    const canReassign =
        (!file?.pending_lock_contact_id || file?.pending_lock_contact_id == cnt_id) &&
        file?.workflow === 'PORTAL'

    return (
        <div>
            <div>
                <Tooltip content={canReassign ? 'Reassign task' : 'Project locked'}>
                    <div
                        onClick={reassignModal.show}
                        className={classnames('d-flex', {
                            'cursor-pointer': canReassign,
                        })}
                        style={{ width: 'fit-content' }}
                    >
                        <EmployeeAvatar
                            fullName={file?.task?.current_employee_name}
                            includeLabel
                            labelClassName={`fs-sm ms-1 text-nowrap ${
                                canReassign ? 'text-primary' : ''
                            }`}
                            size='xxs'
                        />
                        {!canReassign && (
                            <div style={{ marginLeft: 5, marginTop: -5 }}>
                                <span className='d-block p-0 m-0 mt-2 fs-xs'>
                                    <i className='fas fa-xs fa-lock text-muted' />
                                </span>
                            </div>
                        )}
                    </div>
                </Tooltip>
            </div>
            <small className='d-block ms-4 text-muted fst-italic'>
                {dayjs(file?.assigned_date).fromNow()}
            </small>
            {reassignModal.isOpen && (
                <AddEditNoteModal
                    title={`Reassign ${file?.task?.code_label} - ${file?.ncs_lno}`}
                    fileInfo={file}
                    show={reassignModal.isOpen}
                    saveFn={(data) => {
                        mutate(data)
                    }}
                    tags={selectedTags}
                    loading={isLoading}
                    cancel={reassignModal.hide}
                    assigned_emp_id={file?.task?.current_employee_id}
                />
            )}
        </div>
    )
}

function isUrgent(dateString, days = 2) {
    const now = dayjs()
    const targetDate = dayjs(dateString)

    const diffInDays = targetDate.diff(now, 'day')

    return diffInDays <= days
}
